import { useEffect, useState, useRef } from "react";
// import * as APIHelper from '../Helper/mockAPIFetch';
import * as APIHelper from '../Helper/apiFetch';
import * as nativeEvents from '../Helper/native';
import { useNavigate } from "react-router-dom";
import RenderShimmer from "../components";

export default function Loading() {
    const tokenCheckTimerRef = useRef(null);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    useEffect(() => {
        tokenCheckTimerRef.current = setInterval(async () => {
            const url = new URL(window.location.href);
            const tokenHeader = url?.searchParams.get('token');
            const refreshToken = url?.searchParams.get('refreshToken');

            tokenHeader && sessionStorage.setItem('AccessToken', tokenHeader);
            refreshToken && sessionStorage.setItem('RefreshToken', refreshToken);
            
            const jwt = tokenHeader || sessionStorage.getItem('AccessToken');

            if (jwt) {
                clearInterval(tokenCheckTimerRef.current);
                navigate('/home');
            }
        }, 2000);

        APIHelper.postAnalytics("ipl_banner");
        window.onpopstate = e => {
            console.log(e);
            nativeEvents.goBack()
        }

        return () => {
            clearInterval(tokenCheckTimerRef.current);
        }
    }, [])

    return (
        <div style={{ marginTop: '-20px', paddingTop: '20px' }}>
            <RenderShimmer />
        </div>
    );
}