import React, { useEffect, useState } from 'react';
import { ShimmerButton } from "react-shimmer-effects";
import { Link } from 'react-router-dom';
import quiz from '../images/quizHeaderImg.png';
import rewardBg from '../images/rewardClaimBg.png';
import winSatsIcon from '../images/winSatsIcon.png';
import sats from '../images/sats.png';
// import Notify from './notify';
import { MdOutlineQuiz } from "react-icons/md";
import * as APIHelper from '../Helper/apiFetch';
import Lottie from "lottie-react";
import CongLottie from '../../src/conglottie.json';
import wowLottie from '../../src/wowlottie.json';


const initiaData = require('./urlEncodeTest.json')
const CLAIMED_REWARDS_MAP = {
    NOT_PLAYED: 0,
    WON_REWARD: 1,
    NO_REWARD: 2
}

const shimmer = [
    {
        rows: 1,
        width: 100,
        height: 24
    },
    {
        rows: 1,
        width: "100%",
        height: 35
    },
    {
        rows: 1,
        width: "100%",
        height: 65
    }
]
const REWARD_TYPE = {
    SATS: 0,
    PERCENTAGE: 1,
    CARDDISCOUNT:3
}

const DailyQuiz = () => {
    const [alreadyClimed, setAlreadyClimed] = useState(initiaData?.streak.alreadyClimedReward);
    const [rewardType, setRewardType] = useState(-1)
    const [bgcolor, setbgcolor] = useState("");
    const [climed, setClimed] = useState(CLAIMED_REWARDS_MAP.NOT_PLAYED);
    const [loading, setLoading] = useState(false);
    const [enableQuiz, setEnableQuiz] = useState(false);

    const quizSteak = initiaData?.streak.quizDetails;
    const statusNotify = initiaData?.notify.isNotifyActive.status;

    useEffect(() => {
        setLoading(true);
        APIHelper.quizInfo().then(resp => {
            if (!resp.error) {
                setEnableQuiz(true);
                setLoading(false);
                setClimed(climed => {
                    if (resp.rewards !== undefined) {
                        setRewardType(x => {
                            switch (resp.rewards.type) {
                                case "sats":
                                    return REWARD_TYPE.SATS
                                case "card-percentage":
                                    return REWARD_TYPE.PERCENTAGE
                                case "card-discount":
                                    return REWARD_TYPE.CARDDISCOUNT
                                default:
                                    return -1
                            }
                        })
                        climed = resp.rewards.value === 0 ? CLAIMED_REWARDS_MAP.NO_REWARD : CLAIMED_REWARDS_MAP.WON_REWARD
                        setAlreadyClimed(aClimed => {
                            aClimed.quizClimedReward.sats = resp?.rewards?.code ? resp?.rewards?.code : resp?.rewards?.value;
                            return aClimed;
                        })
                    }
                    return climed;
                });
            }
        }).catch(error => console.log(error))
    }, [])

    useEffect(() => {
    }, [climed])

    useEffect(() => {
        setbgcolor(quizSteak?.bgColor)
    }, [quizSteak])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: CongLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultLottie = {
        loop: true,
        autoplay: true,
        animationData: wowLottie,
        rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
        }
    };

    const contentDisplay = () => {
        return (
            <div style={{ position: 'relative' }}>
                {/* <Notify title={"Participate to earn Bitcoin rewards!"} /> */}
                <div>
                    <img className='quizImg' src={quiz} alt=" " />
                    <h2 className='purchaseRectangleBgOne'>{quizSteak.title}</h2>
                    <div className='purchaseStreakSubTitle'>{quizSteak.msg1}</div>
                </div>
                <div className='purchaseStreakButton-container'>
                    {
                        enableQuiz ? (
                            <Link to='/quiz' className="shopButton" >
                                <MdOutlineQuiz size={19} className="buttonIicon" />
                                {quizSteak.buttonTitle}
                            </Link>) : (
                            <Link to='/' onClick={(event) => event.preventDefault()} className="shopButton" >
                                <MdOutlineQuiz size={19} className="buttonIicon" />
                                {quizSteak.buttonTitle}
                            </Link>
                        )
                    }
                </div>
            </div>

        )
    }

    const getRewardMessage = () => {
        switch (rewardType) {
            case REWARD_TYPE.SATS:
                return (
                    <div className='winSats-Title'>
                        {alreadyClimed.quizClimedReward.climedTitle}
                        <span className='rewardSats'>
                            <img className='rewardSats-icon' src={sats} alt=' ' />
                            <span dangerouslySetInnerHTML={{__html:alreadyClimed.quizClimedReward.sats}} />
                        </span>
                    </div>
                    )
            case REWARD_TYPE.PERCENTAGE:
                return (
                    <div className='winSats-Title' 
                        dangerouslySetInnerHTML={{__html: alreadyClimed.quizClimedReward.climePerReward.replace('$', alreadyClimed.quizClimedReward.sats)}} 
                    />
                    )
            case REWARD_TYPE.CARDDISCOUNT:
                    return (
                        <div className='winSats-Title'
                        dangerouslySetInnerHTML={{__html: alreadyClimed.quizClimedReward.cardDiscount.replace('$', alreadyClimed.quizClimedReward.sats)}}/>
                    )
            default:
                return (
                    <div className='winSats-Title'>
                    </div>
                )
        }
    }

    const alreadyClimedReward = () => {
        return (
            <div style={{ position: 'relative' }}>
                <div>
                    <img className='quizImg' src={quiz} alt=" " />
                    <h2 className='quizTitle'>{alreadyClimed.quizClimedReward.title} </h2>
                    <p className='purchaseStreakSubTitle'>{alreadyClimed.quizClimedReward.msg1}</p>
                    <div className='climedReward-container'>
                        {/*  <div className='winSatsIcon-container'>
                            <img src={winSatsIcon} className='winSatsIcon' alt=' ' />
                        </div> */}
                        <div style={{width: '50px', height:'auto'}}>
                        <Lottie
                            options={defaultOptions}
                            height={70}
                            width={70}
                        />
                        </div>
                        {getRewardMessage()}
                        <div>
                            <img src={rewardBg} alt='' className='climeCardBg' />
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const didntAnswerQuiz = () => {
        return (
            <div style={{ position: 'relative' }}>
                <div>
                    <img className='quizImg' src={quiz} alt=" " />
                    <h2 className='quizTitle'>{alreadyClimed.didntAnswerQuiz.title} </h2>
                    <p className='purchaseStreakSubTitle'>{alreadyClimed.didntAnswerQuiz.msg1}</p>
                    <div className='climedReward-container'>
                        {/* <div className='winSatsIcon-container'>
                            <img src={winSatsIcon} className='winSatsIcon' alt=' ' />
                        </div> */}
                        <Lottie
                            options={defaultLottie}
                            height={70}
                            width={125}
                            // style={{margin: '10px'}}
                        />
                        <div className='winSats-Title'>{alreadyClimed.didntAnswerQuiz.didntAnswerTitle}
                            {/* <div className='rewardSats'>
                            </div> */}
                        </div>
                        <div>
                            <img src={rewardBg} alt='' className='climeCardBg' />
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    const shimerLoader = () => {
        return (shimmer?.map((data, index) => {
            return (
                <div key={index}>
                    <ShimmerButton size={{ height: data?.height, width: data?.width }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows} foreground={'#191B30'} background={'#292B47'} />
                </div>
            )
        }
        ))
    }


    const renderQuizComponent = () => {
        console.log(climed)
        switch (climed) {
            case CLAIMED_REWARDS_MAP.NOT_PLAYED:
                return contentDisplay()
            case CLAIMED_REWARDS_MAP.WON_REWARD:
                return alreadyClimedReward();
            case CLAIMED_REWARDS_MAP.NO_REWARD:
                return didntAnswerQuiz();
            default:
                return contentDisplay()
        }
    }

    return (
        <div>
            <div className='purchaseStreak-sectionOne' style={{ backgroundColor: bgcolor }}>
                {loading ? shimerLoader() : renderQuizComponent()}
            </div>
        </div>
    );
}

export default DailyQuiz;