import React, { useEffect, useState } from "react";
// import * as APIHelper from '../../Helper/mockAPIFetch';
import * as APIHelper from '../../Helper/apiFetch';
import { useNavigate } from 'react-router-dom';
import RenderTimer from "./RenderTimer";
import UserPrediction from "./UserPrediction";
import VS from '../../images/VS.png';
import predictCheck from '../../images/Checkbox.png';
import predictWrong from '../../images/cancel.png';
import { MdLockOutline } from "react-icons/md";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import { HiCheckCircle } from "react-icons/hi";
import Congratulation from "../congratulation";
import winSatsIcon from '../../images/congratsIcon1.png';
import sats from '../../images/sats.png';

const initiaData = require('../urlEncodeTest.json');

const Predict = () => {
    const [shimmeLoader, setShimmer] = useState(false);
    const [listTeam, setListTeam] = useState([]);
    const [matchStatuses, setMatchStatuses] = useState({});
    const [userChoices, setUserChoices] = useState({});
    const [selectedTeams, setSelectedTeams] = useState({});
    const [loadingMatches, setLoadingMatches] = useState([]);
    const [congratsPopup, setCongratsPopup] = useState(false)
    const [congratsTitle, setCongratsTitle] = useState(initiaData.congratulations);

    const navigate = useNavigate();
    useEffect(() => {
        const fetchMatchData = async () => {
            setShimmer(true);
            try {
                const resData = await APIHelper.predictGetChoice();
                setShimmer(false);
                if (resData.error) {
                    navigate('/error');
                    return;
                }
                const currentDate = Date.now();
                const updatedMatchStatuses = {};
                const updatedUserChoices = {};

                resData.forEach(match => {
                    const matchDate = new Date(match.date).getTime();

                    if (match.result) {
                        updatedMatchStatuses[match.id] = "ended";
                    } else if (matchDate <= currentDate) {
                        updatedMatchStatuses[match.id] = "started";
                    } else {
                        updatedMatchStatuses[match.id] = "notstarted";
                    }

                    updatedUserChoices[match.id] = match.userPrediction.choice;
                });

                setListTeam(resData);
                setMatchStatuses(updatedMatchStatuses);
                setUserChoices(updatedUserChoices);
            } catch (error) {
                console.error("Error fetching match data: ", error);
            }
        };

        fetchMatchData();
    }, [navigate]);

    const shimmer = [
        {
            rows: 1,
            width: '100%',
            height: 60,
            width2: '100%',
            height2: 50,
            rows2: 1,
            width3: 120,
            height3: 105,
            height4: 20,
            width4: '100%'
        }
    ]

    const shimerLoader = () => {
        return (shimmer?.map((data, index) => {
            return (
                <div style={{ height: '380px', width: 'auto', padding: '0px' }} className="matchContainer" key={index}>
                    <div className="shimmer-effect">
                    </div>
                </div>
            )
        }
        ))
    }

    const handleMatchStatusChange = (matchId, status) => {
        setMatchStatuses(prevStatuses => ({
            ...prevStatuses,
            [matchId]: status
        }));
    };

    const handleTeamSelect = (index, matchId) => {
        setSelectedTeams(prevSelectedTeams => ({
            ...prevSelectedTeams,
            [matchId]: index + 1
        }));
        /* setUserChoices(prevSelectedTeams => ({
            ...prevSelectedTeams,
            [matchId]: index + 1
        })); */
        // console.log("User selected team:", index + 1, "from match:", matchId);
    };

    const handelConfirmTeam = async (matchId, selectedTeam) => {
        try {
            setLoadingMatches(prevLoadingMatches => ({ ...prevLoadingMatches, [matchId]: true })); // Set loader to true for this match
            const resp = await APIHelper.predictPickChoice(matchId, selectedTeam);
            if (resp.error) {
                navigate('/error');
                return;
            }
            setListTeam(matches => {
                return matches.map(match => {
                    if (match.id === matchId) {
                        return {
                            ...match,
                            userPrediction: {
                                ...match.userPrediction,
                                choice: selectedTeam
                            },
                            predictions: resp.predictions
                        };
                    }
                    return match;
                });
            });
            setUserChoices(prevUserChoices => ({ ...prevUserChoices, [matchId]: selectedTeam }));
        } catch (e) {
            console.log("Error", e);
        } finally {
            setLoadingMatches(prevLoadingMatches => ({ ...prevLoadingMatches, [matchId]: false })); // Remove the match ID from loadingMatches after API response
        }
    };

    const handelClaimReward = async (matchId) => {
        try {
            await APIHelper.claimReward(matchId).then(resp => {
                if (resp.error) {
                    navigate('/error');
                }
                setCongratsPopup(true);
                /* setListTeam(matches => {
                    const currentMatch = matches[current];
                    resp.reward.status = 2;
                    currentMatch.userPrediction['rewards'] = resp.reward;
                    currentMatch.rewardsEnabled = false;
                    return matches;
                }) */
                setCongratsTitle(title => {
                    if (resp.matchResult === 3)
                        return initiaData.streak.CongratsPopupDrawMatch;

                    title.reward = resp.reward.value;
                    if (resp.reward.type === "sats") {
                        title.msg1 = title.msg1?.replace("$", title.reward)
                    } else {
                        title.msg1 = title.percentageTitle?.replace("$", title.reward)
                    }
                    return title;
                })
            })
        } catch (e) {
            console.log("Error", e);
        }
    };

    return (
        <div>
            {
                shimmeLoader ? shimerLoader() :
                    listTeam.length === 0 ?
                        <div style={{ color: "#18D887",display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems:'center', height: '380px', width: 'auto', padding: '0px' }} className="matchContainer">
                            There is no match today!!!...
                        </div>
                        :
                        listTeam.map((match, matchIndex) => (
                            <div className="matchContainer" key={matchIndex}>
                                <div className="predictTimerCountContainer">
                                    <div className="matchCountContainer">
                                        MATCH {matchIndex + 1}
                                    </div>
                                    <div>
                                        <RenderTimer match={match} matchStatus={matchStatuses[match.id]} handleMatchStatusChange={handleMatchStatusChange} />
                                    </div>
                                </div>
                                <div className="matchSubContainer">
                                    {match.teams.map((team, teamIndex) => (
                                        <React.Fragment key={teamIndex}>
                                            <div className="teamLogoContainer">
                                                <div>
                                                <div className="captainImageBg" style={{zIndex: 111, position: 'absolute'}}></div>
                                                    <div className="layoutGrid" style={{zIndex: 888, position: 'relative'}}></div>
                                                </div>
                                                <div>
                                                    <img src={team.captains} alt=" " style={{zIndex: 444, position: 'relative', marginTop: '-200px'}} className="captainsImg" />
                                                </div>
                                                <div className="team" onClick={matchStatuses[match.id] === "notstarted" && userChoices[match.id] === -1 ? () => handleTeamSelect(teamIndex, match.id) : null}>
                                                    {
                                                        <div style={{
                                                            backgroundColor: "212426",
                                                            border: "1px solid #A6A7A8",
                                                            borderRadius: "22px",
                                                            width: "20px",
                                                            height: "20px",
                                                            margin: "10px 10px 0px 100px"
                                                        }}>
                                                            {(userChoices[match.id] === teamIndex + 1 || selectedTeams[match.id] === teamIndex + 1) ?
                                                                <img src={predictCheck} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                                    <img src={team.image} alt=" " className="teamFlag" />
                                                    <div className="teamTitle" >{team.name}</div>
                                                </div>
                                            </div>
                                            {teamIndex !== match.teams.length - 1 && <div key='vs' className="vs"><img className="vsImg" src={VS} alt=" " /></div>}
                                        </React.Fragment>
                                    ))}
                                </div>
                                {match.predictions && match?.userPrediction.choice !== -1 && <UserPrediction match={match} />}
                                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                                    <RenderPredictTitle match={match} matchStatus={matchStatuses[match.id]} listTeam={listTeam} current={matchIndex} userChoices={userChoices} />
                                </div>
                                {
                                    listTeam.length !== 0 ?
                                        <div className="confermTeamCTA-container">
                                            {
                                                // match?.result === userChoices[match.id] ?
                                                match?.result ?
                                                    match?.result && userChoices[match.id] === -1 || match?.userPrediction?.rewards?.status === 2  ?
                                                        null
                                                        :
                                                        <ClaimButton match={match} btnDisable={false} loader={false} handelClaimReward={handelClaimReward} />
                                                    :
                                                    <RenderConfirmButton match={match} userChoices={userChoices} matchStatus={matchStatuses[match.id]} btnDisable={false} loader={loadingMatches[match.id]} handelConfirmTeam={handelConfirmTeam} selectedTeams={selectedTeams} />
                                            }
                                        </div>
                                        :
                                        null
                                }
                                {match.userPrediction?.rewards?.status === 2 ?
                                    <div className='climedReward-container'>
                                        <div className='winSatsIcon-container'>
                                            <img src={winSatsIcon} className='winSatsIcon' alt=' ' />
                                        </div>
                                        {match.userPrediction?.rewards?.type === "sats" ?
                                            <div className="rewardWonTitle">
                                                <div> You have claimed your reward! <img src={sats} width='15px' height='auto' />{match.userPrediction?.rewards?.value}</div>
                                            </div>
                                            :
                                            <div className="rewardWonTitle">
                                                <span> You’ve won <span>{match.userPrediction?.rewards?.value}</span>% extra Bitcoin reward on your next transaction.</span>
                                            </div>
                                        }

                                    </div>
                                    : null}
                            </div>
                        ))
            }
            <div>
                {congratsPopup && <Congratulation title={congratsTitle} />}
            </div>
        </div>
    );
};

const RenderPredictTitle = ({ match, matchStatus, listTeam, userChoices }) => {
    if (matchStatus === "ended") {
        if (match?.result && match?.userPrediction?.rewards?.status !== 2) {
            if (match?.result === 3) {
                return (
                    <div>The match result was a draw. Here's a consolation reward!</div>
                )
            } else if (match?.result !== userChoices[match.id]) {
                return (
                    <div style={{ color: '#FF8978' }}>Unfortunately your prediction was wrong. As a token of your participation, you get 5% extra Sats back on your next purchase.</div>
                )
            }
            return (
                <div>Congratulations! You predicted right and are eligible for a reward!</div>
            )
        } else if (match?.rewardsEnabled === false && match?.userPrediction?.rewards?.status === 2) {
            return (
                <div>You have already claimed your reward for this match. Keep predicting to win more rewards!</div>
            )
        } else if (match.userPrediction.choice !== -1) {
            return (
                <div style={{color: '#FF8978'}}>Unfortunately your prediction was wrong. As a token of your participation, you get 5% extra Sats back on your next purchase.</div>
            )
        }
        else {
            return (
                <div>This match is over! Keep predicting for more matches to win up to <span className="predict-subtitle-Two">10,000 Sats</span></div>
            )
        }
    } else {
        if (listTeam.length === 0) {
            return (
                <div style={{ color: "#18D887" }}>
                    There is no match today!!!...
                </div>
            )
        } else if (match?.userPrediction?.choice !== -1) {
            return (
                <div style={{ color: "#18D887" }}>Your choice is confirmed! Claim rewards once the match is over.</div>
            )
        } else {
            return (
                <div>Predict the winner of IPL matches and stand a chance to win <span className="predict-subtitle-Two">10,000 Sats</span></div>
            )
        }
    }
}

const RenderConfirmButton = ({ match, matchStatus, userChoices, btnDisable, loader, handelConfirmTeam, selectedTeams }) => {

    const alreadyPredicted = userChoices[match.id] !== -1;
    const selectedTeam = selectedTeams[match.id] || -1;
    const predictionConfirmed = alreadyPredicted && selectedTeam !== -1;

    const disabled = alreadyPredicted || selectedTeam === -1 || predictionConfirmed || loader;
    let btnStyle = { background: "#1F4839" };

    if (selectedTeam !== -1 && alreadyPredicted)
        btnStyle = { background: '#1B2825', color: '#18D887' }
    else if (selectedTeam !== -1)
        btnStyle = { background: '#DA513B', color: '#FFFFFF' }
    else if (!alreadyPredicted)
        btnStyle = null;

    let cnfText = "CONFIRM";
    if (matchStatus !== "notstarted")
        cnfText = "PREDICTION TIME IS CLOSED"

    return (
        <button className="confermTeamCTA"
            disabled={disabled}
            onClick={() => handelConfirmTeam(match.id, selectedTeam)}
            style={btnStyle}
        >
            {loader ?
                <Spinner animation="border" size="sm" style={{ color: '#FFFFF', marginTop: '6px', marginBottom: '6px' }} /> :
                <span>{alreadyPredicted ?
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <HiCheckCircle size={22} color="#18D887" /> CONFIRMED
                    </span>
                    :
                    <div>
                        {matchStatus !== "notstarted" ?
                            <span>
                                <MdLockOutline size={24} color='#A6A7A8' style={{ marginRight: '10px' }} />
                            </span>
                            :
                            null
                        }
                        {cnfText}
                    </div>}
                </span>
            }
        </button>
    );
};

const ClaimButton = ({ match, btnDisable, loader, handelClaimReward }) => {
    const [loadingMatchId, setLoadingMatchId] = useState(null);


    return (
        <button className="confermTeamCTA"
            disabled={btnDisable || loadingMatchId === match.id}
            onClick={async () => {
                setLoadingMatchId(match.id);
                try {
                    await handelClaimReward(match.id);
                } catch (error) {
                    console.error("Error claiming rewards: ", error);
                } finally {
                    setLoadingMatchId(null);
                }
            }}
            style={{ background: '#DA513B', color: '#FFFFFF' }}>
            <span>
                {loadingMatchId === match.id ?
                    <Spinner animation="border" size="sm" style={{ color: '#FFFFF', marginTop: '6px', marginBottom: '6px' }} />
                    :
                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdOutlineCardGiftcard size={22} color='#FFFFFF' /> CLAIM REWARD</span>
                }
            </span>
        </button>
    );
};

export default Predict;