
import { useEffect, useState } from 'react';
import LandingImg from '../images/LandingImg.png';
import { useNavigate } from 'react-router-dom';

export default function LandingScreen(props) {
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [hash, setHash] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        let newHash = (window.location.pathname).split('/cc/')[1]
        if (newHash)
            setHash(newHash)
    }, [])

    const handleSubmit = async () => {
        setBtnDisabled(true);
        try {
            navigate('/cc/inputPhoneNum', { state: { hash } })
        } catch (e) {
            console.log("Error", e)
        }

    }

    return (
        <div className="landingContainer">
            <div className='landingScreenImgContainer'>
                <img src={LandingImg} width={'285px'} height={"auto"} className="landingScreenImg" />
            </div>
            <div className='landingScreenSubtitle'>
                <ul>
                    <li>
                        Load INR and spend anywhere in India
                    </li>
                    <li>
                        Earn Bitcoin on every spend
                    </li>
                    <li>
                        Choose between flat 1.5% reward or the Bitcoin wheel    
                    </li>
                    <li>
                        Chance of winning upto 1 full Bitcoin on spinning the Bitcoin wheel
                    </li>
                </ul>
            </div>
            <button disabled={btnDisabled} onClick={handleSubmit} className='inputContinueBtn' style={{ color: '#FFFFFF', background: '#D1964A' }} >
                Proceed
            </button>
        </div>
    )
}