import React, { useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { MdOutlineQuiz } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

const FAQ = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return (
        <div className='faqContainer'>
            <div className="accordion-title d-flex justify-content-between align-items-center" onClick={() => setIsActive(!isActive)}>

                <div className='faq-container d-flex'>
                    <div className='faq-icon-contaner'>
                        <MdOutlineQuiz size={24} color="#E91388" />
                    </div>
                    <span className='faqTitle'>FAQs</span>
                </div>

                <div className="col-1 d-flex justify-content-end"><span className="row toggleBtn">{isActive ? <CgClose size={22} /> : <BsPlusLg />}</span></div>
            </div>
            {isActive && <div className="accordion-content subText">
                <div>
                    <ul className='termAndConditionPoints mt-4'>
                        <li>
                            <p className='FAQ-Question'>What is the Bitcoin World Cup Mania Cricket Trivia Contest?</p>
                            <p className='paragraph'>The Bitcoin World Cup Mania Cricket Trivia Contest is a contest running on the GoSats app during the ICC World Cup 2023. Users are presented with 3 multiple-choice questions (MCQs) about cricket daily and have 20 seconds to answer each question.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>When does the contest end?</p>
                            <p className='paragraph'>The contest ends on November 19, 2023, coinciding with the conclusion of the ICC World Cup 2023.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>How many questions are presented daily?</p>
                            <p className='paragraph'>Users are presented with 3 MCQ questions daily.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Will I get streak reward for transactions where my preference is gold?</p>
                            <p className='paragraph'>No you will not be eligible for the same as the GoSats Bitcoin World Cup Mania is focused only on the Bitcoin rewards aspect whereas Gold rewards are enhanced on account of the festive season to 2% back if in Gold on the elite card usage.</p>
                        </li>
                        <a style={{ color: '#D1964A', textDecoration: 'none' }} onClick={() => { navigate('/faq') }} className='tAndc'>View more <FiChevronRight style={{width: '18px', height: '18px', color:'#D1964A'}} className='back-button-icon' /></a>
                    </ul>
                </div>
            </div>}
        </div>
    );
}

export default FAQ;