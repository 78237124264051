import CardCashback from "./CardCashback";
import FlatSats from "./FlatSats";
import MerchantDiscount from "./MerchantDiscount";
import ReferralBonus from "./ReferralBonus";
import SendGift from "./SendGift";
import TwoxRewards from "./TwoxRewards";
import NoCard from "./NoCard";

const giftType = {
    NoCard,
    CardCashback,
    FlatSats,
    MerchantDiscount,
    ReferralBonus,
    SendGift,
    TwoxRewards
}
export default giftType;