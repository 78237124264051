import { ClipLoader } from 'react-spinners';

export default function ScratchCardLoader  () {
    return (
        <div className="rewardLoader" >
            <div style={{ color: '#FFFFFF' }}>
                <div style={{ display: 'flex', justifyContent: 'center', }}>
                    <ClipLoader size={24} color={'#D1964A'} />
                </div>
                <div style={{ width: '200px', textAlign: 'center', marginTop: '15px', fontSize: '18px', fontWeight: 600 }}>
                    Please wait,Rewards are loading...
                </div>
            </div>
        </div>
    )
}