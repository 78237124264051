import CongratsImg from '../images/congrats.png';
import Store from '../images/store.png';
export default function CongratsScreen() {

    return (
        <div className="congratsTitleContainer">
            <div>
                <div className="congratsTitle">Congratulations!</div>
                <div className="congratsSubTitle">You’re a GoSats Card holder now!</div>
            </div>
            <div style={{ marginTop: '35px', marginBottom: '65px' }}>
                <img src={CongratsImg} width={'217px'} height={'auto'} />
            </div>
            <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 400, marginBottom: '30px' }}>
                In order to complete the registration process, install GoSats App <a href='https://gosats.onelink.me/O1LB/628b4c27'><img src={Store} width={'50px'} height={'auto'} /></a>
            </div>
            <form action="https://gosats.onelink.me/O1LB/628b4c27">
                <button className='inputContinueBtn' style={{ background: '#D1964A', color: '#FFFFFF', fontSize: '16px', fontWeight: 600 }}>
                    Download the App
                </button>
            </form>
        </div>
    )
}