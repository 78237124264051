export function goBack() {
    console.log('NATIVE EVENT: GO BACK');
    const data = {
        event: 'back'
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function shopNow() {
    console.log('NATIVE EVENT: SHOP NOW');
    const data = {
        event: 'Shop'
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function offersBrands() {
    console.log('NATIVE EVENT: SHOP BRANDS');
    const data = {
        active: true,
        event: 'navigate',
        location: 'Dashboard',
        data: {
            screen: 'Brands',
            params: { scrollToSection: 'Valentine’s Day Offer' }
        },
        action: {
            event: '',
            name: 'navigate',
            path: 'SectionBrands',
            scrollToVoucherSection: 'Valentine’s Day Offer'
        }
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function purchaseCard(code) {
    console.log('NATIVE EVENT: BUY CARD');
    if (window?.navigator?.clipboard) {
        window.navigator.clipboard.writeText(code);
    }
    const data = {
        data: {
            code: code
        },
        event: 'navigate',
        location: 'Card'
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function shareWithFriends(message) {
    const msg = `${message} `;
    console.log('NATIVE EVENT: SHARE WITH FRIENDS', message);
    const data = {
        event: 'share',
        message: msg
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function shareReferral(code, link) {
    // const msg = `Hi 😃. There’s an app that gives you bitcoin rewards on every transaction you do, i.e. shopping, payments etc. I found it to be great. Do check it out. Use my referral code ${code} to signup on GoSats. On doing so, 10,000 sats will be credited to both of us, after your first purchase 😄.\nDownload the app now: ${link}`
    const msg = `Heyy! 😃 Use my personal GoSats Valentines Week invite to earn Flat 10% rewards in Gold or Bitcoin on brands like Swiggy, Zomato, Myntra, Nykaa and many more with the GoSats Elite card. Offer ends on 14th February, end of day. Thank me later!\nGet now: https://gosats.onelink.me/O1LB/pj1bifwh`
    console.log('NATIVE EVENT: SHARE WITH FRIENDS', msg);
    const data = {
        event: 'share',
        message: msg
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function goHistory() {
    console.log('NATIVE EVENT: HISTORY');
    const data = {
        event: "TabNavigate",
        location: "History"
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function goMerchant(id) {
    console.log('NATIVE EVENT: MERCHANT PAGE');
    const data = {
        event: "navigate",
        location: "BrandDetail",
        data: {
            id: id
        }
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
}