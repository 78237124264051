import React, { useState } from 'react';
import { MdOutlineTextSnippet } from 'react-icons/md';
import { BsPlusLg } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

const TermsAndCondition = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <div className='tAndcContainer' onClick={() => { navigate('/TermsAndCondition') }}>
                <div className="accordion-title d-flex justify-content-between align-items-center" onClick={() => setIsActive(!isActive)}>
                    <div className="col-11 title">
                        <div className='faq-container d-flex '>
                            <div className='terms-icon-container'>
                                <MdOutlineTextSnippet className='terms-icon' size={18} color="#FFFFFF" />
                            </div>
                            <span className='termsTitle'>T&C</span>
                        </div>
                    </div>
                    {/* <div className="col-1 d-flex justify-content-end"><span className="row toggleBtn">{isActive ? <CgClose size={22} /> : <BsPlusLg />}</span></div> */}
                </div>
                {isActive && <div className="accordion-content subText">
                    <div>
                        <ul className='termAndConditionPoints mt-4'>
                            <li><p>This contest (“CRICKET WORLD CUP MANIA”) is being run and organised by Saffroncoin Technologies Pvt Ltd (“GoSats”, “Organizer”) on mobile application, ie. GoSats.</p> </li>
                            <li>
                                <p>DURATION OF THE CONTEST<br />
                                    The Contest Period shall be from 05th October to 19th november 2023 <a style={{ color: '#D1964A' }} onClick={() => { navigate('/TermsAndCondition') }} className='tAndc'>read more.</a></p>
                            </li>
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TermsAndCondition;


