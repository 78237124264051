import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import * as nativeEvents from '../Helper/native';
import { FiChevronLeft } from "react-icons/fi";
import { BsShareFill } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import './index.css';
import FAQ from "./valentineEliteFaq";
import TandC from "./introNonCard";
import EliteTAndC from "./eliteTandC";
import IntroNonCardFaq from "./valentineIntroNoncardFaq";

const BrandImgs = [
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828373",
        "bgColor": "linear-gradient(180deg, #0C623E 0%, #031D12 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC3960"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828275",
        "bgColor": "linear-gradient(180deg, #0A464D 0%, #021618 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC2989"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828337",
        "bgColor": "linear-gradient(180deg, #5A2F0B 0%, #1C0E03 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC3811"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828374",
        "bgColor": "linear-gradient(180deg, #590D2A 0%, #1F030E 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC8450"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301855094",
        "bgColor": "linear-gradient(180deg, #5F3F0B 0%, #171302 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC5518"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301977354",
        "bgColor": "linear-gradient(180deg, #4D0939 0%, #1B0A03 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC4041"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828336",
        "bgColor": "linear-gradient(180deg, #580C14 0%, #1E030B 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC1529"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828335",
        "bgColor": "linear-gradient(180deg, #10316E 0%, #031521 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC4949"
    }
]

export default function ValentineLandingScreen() {

    const { type } = useParams();
    const targetDate = new Date('2024-02-14T23:59:59');
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

    useEffect(() => {
        // Update remaining time every second
        const timer = setInterval(() => {
            const updatedRemainingTime = calculateRemainingTime();
            setRemainingTime(updatedRemainingTime);
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, []);

    function calculateRemainingTime() {
        const now = new Date();
        const difference = targetDate - now;

        // Check if the target time has already passed
        if (difference < 0) {
            return 0;
        }

        // Convert the difference to hours, minutes, and seconds
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds, days };
    }

    // const type = 'nonCard';
    let subTitle;

    switch (type) {
        case 'elite':
            subTitle = (
                <div className="valentineHeaderSubTitle">
                    Flat <span className="valentineHeaderTitleColor"> 10% Rewards </span> On Your Favourite Brands Vouchers With The Elite Card
                </div>
            );
            break;
        case 'intro':
            subTitle = (
                <div className="valentineHeaderSubTitle">
                    Upgrade & Get <span className="valentineHeaderTitleColor"> Flat 10% </span> Rewards On Your Favourite Brand Vouchers With The Elite Card
                </div>
            );
            break;
        default:
            subTitle = (
                <div className="valentineHeaderSubTitle">
                    Get the GoSats Elite Card For <s>₹1499</s> <span className="valentineHeaderTitleColor"> ₹499 </span> Only
                </div>
            );
    }

    return (
        <div className="valentineLandingScreenContainer">
            <div className="headerNavBar">
                <button className='valentieback-button' onClick={nativeEvents.goBack}>
                    <FiChevronLeft className='valentieback-button-icon' />
                </button>
                <div className="valentieTimerContainer">
                    <div className="valentineTime">
                        <img alt=' ' className='valentineTimerIcon' src={'https://assets.gosats.io/notification/2023/11/1700731674029'} />
                        {remainingTime === 0
                            ? '00:00:00'
                            : `${String(remainingTime.days)}D ${String(remainingTime.hours)}h ${String(remainingTime.minutes)}m`}
                    </div>
                    <div className="valentineShareContainer" onClick={() => nativeEvents.shareReferral()}>
                        <BsShareFill size={24} />
                    </div>
                </div>
            </div>
            <div className="headerContainer">
                <div className="valentineHeaderTitleContainer">
                    <div className="txtBg">
                        <div className="valentineHeaderTitleOne">
                            GoSats
                        </div>
                    </div>
                    <div className="valentineHeaderTitle">
                        Valentines Week
                    </div>
                </div>

                <div className="headerImgContainer">
                    <img alt=" " className="valentineHeaderImg" src="https://assets.gosats.io/notification/2024/2/1707215211077" />
                </div>
                {subTitle}
                <div className="valentineDateContainer">
                    <div className="valentineDate">
                        10 Feb - 14 Feb
                    </div>
                </div>
                <div className="valentineCardContainer">
                    <div className="valentineCardTitle">
                        GoSats card rewards you with <span className="valentineHeaderTitleColor"> Gold And Bitcoin </span> on every spend!
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img alt=" " className="valentineCardIcon" src="https://assets.gosats.io/notification/2024/2/1707287274504" />
                    </div>
                </div>
            </div>
            <div className="topBrandsContainer">
                <div className="valentineTopBrandsTitle">Boosted Rewards On Top Brands</div>
                <div className="valentineTopBrandsIconContainerOne">
                    {
                        BrandImgs.map((data, index) => (
                            <div key={index} className="valentineTopBrandsIconContainer" style={{ background: data.bgColor, border: data.borderColor }} onClick={() => nativeEvents.goMerchant(data.merchantId)}>
                                <div>
                                    <img className="valentineTopBrandsIcon" alt=" " src={data.imgUrl} />
                                </div>
                                <div className="valentineTopBrandsIconTitleContainer">
                                    <div className="valentineTopBrandsIconTitle">
                                        {data.faltReward} <FaArrowRight style={{ marginLeft: '10px' }} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div>
                
                {
                    type === 'intro' || type === 'noncard' ? <IntroNonCardFaq /> : <FAQ /> 
                }
            </div>
            <div>
                {
                    type === 'intro' || type === 'noncard' ? <TandC /> : <EliteTAndC />
                }
            </div>
            <div className="valentineViewButtonContainer">
                <button className="valentineViewButton" onClick={() => nativeEvents.offersBrands()}>
                    View Offer
                </button>
            </div>
        </div>
    );
}