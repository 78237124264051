import * as nativeEvents from '../Helper/native';
import { FiChevronLeft } from "react-icons/fi";
import Logo from "../images/logo.png";
import BodyImg from '../images/BodyImg.png';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

export default function CardUser() {

    // const [hash, setHash] = useState(null);

    // useEffect(() => {
    //     console.log(window.location, "Test");
    //     const urlSearchParams = new URLSearchParams(window.location.search);
    //     const params = Object.fromEntries(urlSearchParams.entries());
    //     const { type } = params ?? {};
    //     if (type)
    //         setHash(type)
    // }, [])

    const {type} = useParams();

    return (
        <div className="cardContainer">
            <button className='back-button' onClick={nativeEvents.goBack}>
                <FiChevronLeft className='back-button-icon' />
            </button>
            <div>
                <img src={Logo} width="90px" height="auto" />
            </div>
            <div className="headerTitle">
                REPUBLIC OF BITCOIN
            </div>
            <div className="headerSubtitle">
                Jan 26th - Jan 29th
            </div>
            <div>
                <img src={BodyImg} width="300px" height="300px" />
            </div>
            <div className="bodyTitle">
                Let's celebrate Republic Day together, with boosted <span className="subColor">{type === "intro" ? "1%" : "3%"} Bitcoin rewards</span> on your GoSats Card!
            </div>
            <div className="ctaContainer">
                <button className="cardCta" onClick={nativeEvents.goBack}>
                    Okay
                </button>
            </div>
        </div>
    )
}