import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import timer from "../../images/timer2.png";

const HALF_DAY = 12 * 60 * 60 * 1000;

const RenderTimer = forwardRef(({ matchStatus, match }, ref) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();

    useImperativeHandle(ref, () => ({
        resetTimer: () => {
            clearInterval(timerRef.current);
        },
    }));

    const startTimer = useCallback((startTime) => {
        if (startTime <= Date.now()) {
            clearInterval(timerRef.current);
            return;
        }

        const time = startTime - Date.now();

        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    }, [hours, minutes, seconds]);

    useEffect(() => {

        if (matchStatus === "notstarted" && match.date) {
            const startTime = new Date(match.date).getTime();

            if (startTime <= Date.now()) {
                clearInterval(timerRef.current);
                return;
            }

            clearInterval(timerRef.current);
            timerRef.current = setInterval(() => startTimer(startTime), 1000);
        } else if (matchStatus === "ended" && match?.lastUpdatedOn) {
            const lastUpdatedOn = match.lastUpdatedOn;
            const deadline = lastUpdatedOn + HALF_DAY;

            if (deadline <= Date.now()) {
                clearInterval(timerRef.current);
                return;
            }

            clearInterval(timerRef.current);
            timerRef.current = setInterval(() => startTimer(deadline), 1000);
        }
    }, [match, matchStatus, startTimer]);

    if (matchStatus === "ended") {
        if (match?.rewardsEnabled && match?.userPrediction?.rewards?.status !== 2) {
            clearInterval(timerRef.current);
            timerRef.current = setInterval(() => startTimer(match.date), 1000);
            return (
                <div className="predict-timer">
                    <div className="predict-timer-title">Reward expires in: </div>
                    <img src={timer} className="predict-timer-icon" alt=" " />
                    <div className="predict-time-reminder">
                        <span className="timerBackground">{hours}</span>:{minutes}:{seconds}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="predict-timer">
                    <span style={{ color: "#FFFFFF", size: "12px" }}>Match Ended</span>
                </div>
            );
        }
    } else if (matchStatus === "started") {
        return (
            <div className="predict-timer">
                <div className="liveDot"></div>
                <span style={{ color: "#FB604A", size: "12px" }}>Live</span>
            </div>
        );
    } else if (matchStatus === "notstarted") {
        return (
            <div className="predict-timer">
                <div className="predict-timer-title">Match starts in: </div>
                <img src={timer} className="predict-timer-icon" alt=" " />
                <div className="predict-time-reminder">
                    <span className="timerBackground">{hours}</span>:
                    <span className="timerBackground">{minutes}</span>:
                    <span className="timerBackground">{seconds}</span>
                </div>
            </div>
        );
    }
});

export default RenderTimer;
