import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShimmerButton } from "react-shimmer-effects";
import stacker from '../images/stackers.png';
import timer from '../images/timer.png';
import satsImg from '../images/sats.png';
import TopFive from './topFive';
// import * as APIHelper from '../Helper/mockAPIFetch';
import Icon from '../images/popupIcon.png'; 
import * as APIHelper from '../Helper/apiFetch';
import { useTimerHook } from '../hooks/timerHook';
import JackpotRewards from './jackpotRewards';
// import Notify from './notify';


const shimmer = [
    {
        rows: 1,
        width: 80,
        width2: 50,
        width3: 80
    },
    {
        rows: 1,
        width: 60,
        width2: 70,
        width3: 80
    },
    {
        rows: 1,
        width: 80,
        width2: 50,
        width3: 60
    },
    {
        rows: 1,
        width: 100,
        width2: 40,
        width3: 40
    },
    {
        rows: 1,
        width: 30,
        width2: 40,
        width3: 80
    }
]

var jackpotData = [
    {
        jackpotImg: 'https://assets.gosats.io/notification/2023/3/1679568719223',
        weekNo: 'Week 1',
        date: '30th Oct - 5th Nov'
    },
    {
        jackpotImg: 'https://assets.gosats.io/notification/2023/3/1679568719223',
        weekNo: 'Week 2',
        date: '30th Oct - 5th Nov'
    },
    {
        jackpotImg: 'https://assets.gosats.io/notification/2023/3/1679568719223',
        weekNo: 'Week 3',
        date: '30th Oct - 5th Nov'
    },
    {
        jackpotImg: 'https://assets.gosats.io/notification/2023/3/1679568719223',
        weekNo: 'Week 4',
        date: '30th Oct - 5th Nov'
    }
]

const initiaData = require('./urlEncodeTest.json')

const TopStackers = () => {
    const [top5Data, setTop5Data] = useState();
    const [position, setPosition] = useState(initiaData?.topStacker.positionData);
    const [loader, setLoader] = useState(false);
    const [cardLoader, setCardLoader] = useState(true);
    const [prizes, setPrizes] = useState([]);
    const navigate = useNavigate();
    const formattedTimer = useTimerHook();

   /*  useEffect(() => {
        setCardLoader(true);
        APIHelper.prevWinners().then(resp => {
            if (resp.error) {
                navigate('/error');
            }
            setCardLoader(false);
            console.log(resp.prevWinners);
            sethof(resp.prevWinners);
        })
    }, []) */

    // const hofMapped = [...hof].reverse();

    useEffect(() => {
        setLoader(true);
        APIHelper.topFive().then(resData => {
            console.log(resData)
            if (!resData.error) {
                if (resData.topFive) setTop5Data(resData.topFive);
                if (resData.user) setPosition({ sats: resData.user });
                setPrizes(resData.prizes);
            } else {
                navigate('/error')
            }
            setLoader(false);
        })
    }, [])


    return (
        <div className='stackerContainer'>
            <div>
                {
                    loader ?

                        <div className='hofContainer' style={{ width: "92%", marginLeft: "16px"}}>
                            <div style={{ padding: '10px' }}>
                                <ShimmerButton size={{ height: "70", width: '100%' }} style={{ marginTop: '0px' }} elevation={0.1} rows={1} rounded={'10px'} foreground={'#191B30'} background={'#292B47'}  />
                                <ShimmerButton size={{ height: "70", width: '100%' }} style={{ marginTop: '20px' }} elevation={0.1} rows={1} rounded={'10px'} foreground={'#191B30'} background={'#292B47'}  />
                            </div>
                        </div>
                        :
                        <div>
                            <JackpotRewards jackpotData = {prizes}/>  
                        </div>
                }

            </div>
            <div style={{margin: '20px'}}>
                <div className='topStackers-container'>
                    <img className='stacker-img' src={stacker} alt=' ' />
                    <h5 className='topStackers-title'>Weekly Top Stackers</h5>
                    <div className='topStackers-subtitle'>The top stacker every week wins the jackpot reward. New reward every week.</div>
                    <div>
                        {
                            loader ?
                                <div>
                                    {
                                        shimmer?.map((data, index) => {
                                            return (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '35px' }} key={index}>
                                                    <div style={{ display: 'flex' }}>
                                                        <ShimmerButton size={{ height: "5", width: data?.width }} style={{ marginRight: '10px' }} elevation={0.1} rows={data.rows} rounded={'10px'} foreground={'#333A6100'} background={'#333A61'} />
                                                        <ShimmerButton size={{ height: "5", width: data?.width2 }} style={{ marginBottom: '0px' }} elevation={0.1} rows={data?.rows} rounded={'10px'} foreground={'#333A6100'} background={'#333A61'} />
                                                    </div>
                                                    <ShimmerButton size={{ height: "5", width: data?.width3 }} style={{ marginBottom: '0px' }} elevation={0.1} rows={data?.rows} rounded={'10px'} foreground={'#333A6100'} background={'#333A61'} />
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                                :
                                <div>
                                    <div className='d-flex postion-container'>
                                        <div className='d-flex'>
                                            <span className='position-title'>Your Stacked Sats </span>
                                        </div>
                                        <span className='sats'><img className='sastImg mb-1' src={satsImg} alt=' ' />{position?.sats}</span>
                                    </div>
                                    <div>
                                        {
                                            top5Data && top5Data.map((obj, index) => {
                                                return <TopFive key={index} userData={obj} rank={index + 1} />
                                            })
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopStackers;


