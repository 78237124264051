import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const TermsAndCondition = () => {

    const navigate = useNavigate();

    return (
        <div style={{ padding: "20px", color: "#A6A7A8" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='back-button' onClick={() => { navigate('/home') }}>
                    <FiChevronLeft className='back-button-icon' />
                </button>
                <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 700, marginLeft: '20px' }}>Terms & Conditions</div>
            </div>
            <h2 style={{ marginTop: '20px' }}>Terms and Conditions</h2>
            <p>This contest (“BITCOIN WORLD CUP MANIA”) is being run and organised by Saffroncoin Technologies Pvt Ltd (“GoSats”, “Organizer”)
                on mobile application, ie. GoSats.</p>
            <br />
            <p className="text">DURATION OF THE CONTEST</p>
            <p>The Contest Period shall be from 05th October to 19th November 2023.</p>
            <br />
            <p className="text">ELIGIBILITY</p>
            <p>To participate in the Contest, you must fulfil the following</p>
            <ol>
                <li>
                    Age must be 18 years and above;
                </li>
                <li>
                    You should be an individual legal resident of the Republic of India;
                </li>
                <li>
                    Must have a valid registered account on the GoSats platform and log into the same;
                </li>
                <li>
                    This contest is not available in the state of Tamil Nadu in view of the provisions of the Tamil Nadu Prize
                    Scheme (Prohibition) Act, 1979 or any other place where this is prohibited by law;
                </li>
                <li>
                    Employees of the Organizer shall not be eligible for any of the prizes/rewards/gratification under this
                    Contest. For the purpose of this clause, Employees shall mean those persons who are managing and operating
                    the Contest.
                </li>
            </ol>
            <br />
            <p className="text">HOW TO PARTICIPATE</p>
            <p>This is specified in the contest section of the GoSats mobile application. Please refer to the same.</p>
            <br />
            <p>WINNER SELECTION AND REWARDS</p>
            <p>This is specified in the contest section of the GoSats mobile application. Please refer to the same.</p>
            <br />
            <p className="text">TERMS AND CONDITIONS</p>
            <ol>
                <li>
                    The participation in the Contest is open only for the Contest Period for the Indian citizens of age 18 years
                    and above.
                </li>
                <br />
                <li>
                    Participants who are residents (permanent/temporary) of any state/union territory within the territory of
                    India, wherever such Contest is prohibited by applicable law, shall not be entitled to participate in this
                    Contest.
                </li>
                <br />
                <li>
                    By participating in the Contest, participants consent to some information available on the Organizer
                    platform such as name, details of rewards etc. to be communicated to its vendor as required for publication
                    in any media, for the purpose of Winner's announcement. Organizer may, at its discretion, choose not to
                    disclose the identity of the selected Winner(s) to other participants unless expressly mentioned herein.
                    Participants also consent to the information being stored in the servers of Organizer or its service
                    providers.
                </li>
                <br />
                <li>
                    Participants acknowledge and agree that all copyright and trademarks and all other intellectual property
                    rights in the SMS content, WAP, Mobile application, Website and all material or content related to the
                    Contest shall remain, at all times, owned by the Organizer and/or their respective owners. All material and
                    content contained is made available for Participant's personal and non-commercial use only. Any other use of
                    the material/ content on the platform and/ or any information disseminated by SMS or any other means of
                    communication is strictly prohibited.
                </li>
                <br />
                <li>
                    Subject to any applicable laws; (a) All warranties of any kind, whatsoever, whether express or implied, are
                    hereby expressly DISCLAIMED including, but not limited to, meeting of the Participant's requirements or
                    aspirations, timeliness, security, the results or reliability of the Contest, any type of delay by the
                    Participant/Winner, or the delivery, quality, quantity, participants ability, fitness for use or
                    non-infringement in respect of any goods, services, benefits or prize/reward/gratification/
                    awards acquired or obtained through the Contest or any transactions effected through the Contest; (b) The
                    Participant expressly agree that his/her participation in the Contest is at his/her sole risk and is
                    governed by the T&C herein; and (c) No advice or information whether by representations, oral, written or
                    pictorial derived from the platforms including but not limited to websites/ SMS, shall be construed to mean
                    the giving of any warranty of any kind.
                </li>
                <br />
                <li>
                    The prizes/rewards under this Contest are neither assignable, nor transferable under any circumstance,
                    unless specifically provided hereunder. All the prizes/rewards shall be subject to such other T&C as may be
                    applicable to specific prize/reward, and shall have to be duly complied with by the Winner(s).
                </li>
                <br />
                <li>
                    The prizes/rewards to the Winner(s) shall be given subject to compliance with all applicable statutory
                    legislations, processes and formalities in connection with the prizes/rewards and on production of all such
                    documents/papers as may be required by Organizer before accepting the delivery of the prize/reward. Any
                    failure on the part of the Winner(s) to comply with directions issued by Organizer for claim of prize/reward
                    shall entitle Organizer to forfeit the prizes/rewards or roll the prizes/rewards over to the next eligible
                    Winner(s), at their sole discretion.
                </li>
                <br />
                <li>
                    Any participant providing any incorrect information or concealing/withholding any information from the
                    Organizer with intent to participate and/or be chosen as the Winner(s) of the Contest will automatically be
                    disqualified from participating in it and being chosen as the Winner(s) of the different categories of
                    prize/reward. Such act of providing incorrect information or concealing/withholding any information,
                    including without limitation incorrect declaration of information relating to name, age, identity, address,
                    mobile, connection of Operator number, domicile etc., by the Participant or dispute with the Platform of
                    Organizer, shall entitle the Organizer to disqualify him/her without any further intimation. Organizer
                    reserves its right to take appropriate legal action against any Participant involved in illegal/unauthorized
                    activity,
                </li>
                <br />
                <li>
                    Participants shall indemnify and keep indemnified Organizer and their officers, directors, employees, and
                    affiliates harmless from and against any and all claims, losses, suits, proceedings, action, liabilities,
                    damages, expenses and costs (including attorney's fees and court costs) which Organizer may incur, pay or
                    become responsible as sponsor/organizer/facilitator as a result of (i) breach or alleged breach of the
                    representations or obligations by the participants under the T&C hereunder; (ii) any failure by the
                    participants to comply with applicable law and (iii) any third party claim in respect of misuse of any
                    information of a third party by the participants. Organizers shall have the right to defend themselves,
                    pursuant to this clause, at the cost of the participants. Organizers or their respective affiliates shall
                    not be responsible for any direct, indirect, incidental, special, punitive or consequential damages,
                    including loss of profits, incurred by the Winner(s) or any third party, in connection with the Contest. It
                    is expressly provided that the transaction shall be between Participant & Organizer only.
                </li>
                <br />
                <li>
                    All incidental costs, taxes or levies related to the prize/reward, if any, shall be exclusively borne by the
                    Winner(s), including but not limited to logistics, TDS, gift tax (if any), Insurance and any other taxes
                    applicable by the Central, State or Municipal government. The Participant(s) who have won the prizes/rewards
                    have to make their own arrangements for travel, accommodation to claim the prizes/rewards (if applicable);
                    Organizer shall not be responsible for the same or any cost of travel and accommodation incurred by any of
                    them.
                </li>
                <br />
                <li>
                    Participants hereby irrevocably authorize Organizer and its owners, affiliates, subsidiaries, associates to
                    use the data gathered during and/or after the Contest in respect of the participants (including but not
                    limited to name and photograph) in any of its communications including marketing promotions and
                    advertisements. By participating in the Contest, participants confirm to waive the applicability of rules
                    and regulations of the National Do Not Call Registry and agree to receive updates and promotional
                    messages/SMS/Email/Call for current and future Contests from Organizer.
                </li>
                <br />
                <li>
                    This Contest is subject to force majeure circumstances including without limitation, floods, natural
                    disasters, war, pandemic, act of terror, political unrests, technical snags, act of God, change of laws or
                    any circumstance beyond the reasonable control of Organizer ("Force Majeure Event"). Organizer shall not be
                    liable for any delay or adverse effect caused to this Contest there under as a result of a Force Majeure
                    Event. Organizer shall intimate the suspension of Contest caused as a result of such Force Majeure Event to
                    the participants within reasonable time.
                </li>
                <br />
                <li>
                    Organizer reserves all the rights to Contest any amount/voucher/any other cashback as per its sole
                    discretion and to such number of participants as it may deem fit.
                </li>
                <br />
                <li>
                    This Contest is subject to guidelines/ directions issued by any applicable competent authority from time to
                    time, This Contest is designed/sourced by the Organizer, as per their best of efforts. In case there is any
                    discrepancy or dispute regarding the Contest, the decision by Organizer shall be final and neither Organizer
                    nor any third party shall have any liability towards any Participant. No queries/complaints whatsoever in
                    this regard will be entertained.
                </li>
                <br />
                <li>
                    Organizer reserves the right to extend, cancel, discontinue, prematurely withdraw, change, alter or modify
                    this Contest or any part thereof including the eligibility criteria, the T&C and prize/reward at their sole
                    discretion at any time during its validity as may be required including in view of business exigencies
                    and/or changes by a regulatory authority and/or statutory chances and/or any reasons beyond their control
                    and the same shall be binding on the Participant.
                </li>
                <br />
                <li>
                    Failure by Organizer to enforce any of their rights at any stage does not constitute a waiver of those
                    rights. Further, Organizer may assign any of its rights within the Contest to any party without prior
                    intimation to participants. Organizer does not make any commitment, express or implied to respond to any
                    feedback, suggestion and/or queries of the participants.
                </li>
                <br />
                <li>
                    Organizer neither guarantees nor warranty the quality of the goods being included as prize/reward nor is
                    liable/ responsible for any defect in the goods so obtained/availed by the Winners under this Contest.
                    Organizer shall also not be liable for any defect in the prize/reward included in the contest to the Winner.
                    The Winner further understands that the goods provided as prize/reward will carry the manufacturer's
                    guarantee and in case of any deficiency in the goods, the Participant will contact the manufacturer only.
                </li>
                <br />
                <li>
                    The Winners understand and agree that Organizer its owners and affiliates, shall not be liable for any loss
                    or damage whatsoever that may be suffered, or for any personal loss or injury that may be suffered
                    (including loss of life), to a Winner and/or any third party, directly or indirectly, by use or non-use of
                    the prize/reward.
                </li>
                <br />
                <li>
                    Apart from the entitlement to the prize/reward/cashback, the Winners or their legal heirs/successors/
                    representative will have no other rights or claims against Organizer, its owners/organizers/sponsors and
                    affiliates. Notwithstanding, anything, in case of any dispute, maximum aggregate liability of Organizer,
                    shall not exceed value of the concerned, prize/reward/cashback in case of any dispute in pursuance to the
                    terms and conditions herein.
                </li>
                <br />
                <li>
                    The Winner(s) understand and agree that acceptance of the prize/reward by them, constitutes grant of
                    permission to and hereby irrevocably authorize the Organizer to click photographs of the Winner and use the
                    Winner's names, photograph, preferences (likes and dislikes), voice, comments and other personal details for
                    the purpose of this contest including advertising and promotional purposes in any media worldwide,
                    Organizer's platform or public domain, only for the purposes of advertising and trade without any additional
                    compensation whatsoever to the Winners. The Participant/Winner hereby provides his/her irrevocable, consent
                    for sharing/submission of his/her personal information to Organizer, and subsequent use by Organizer of such
                    information for lawful purposes as per its discretion.
                </li>
                <br />
                <li>
                    Post Winner(s) declaration and completion of their announcement and further process as the case may be if
                    Organizer finds that the declared Winner(s) are erroneous then Organizer reserves the right to declare the
                    correct Winner(s) again (as per the T&C) and cancel the prize/reward, of Winner(s) declared earlier and the
                    same decision of Organizer shall not be challenged by any erroneously selected Winners.
                </li>
                <br />
                <li>
                    Organizer shall not be responsible for any claims from any Participant/Winner(s) or any third party arising
                    out of any failure(s) which is beyond the reasonable control of the Organizer. Without limiting the
                    generality of the foregoing, the Organizer shall not be responsible for any claims arising out of technical
                    failures before, during or after the completion of the Contest including but not limited to any technical
                    failures in the mobile/device/computer/laptop connectivity, delay in sending or receiving the SMS for
                    participating in the Contest, any type of delay, any action that has been initiated, actioned or completed
                    through his/her mobile phones/device/computer/laptop, failure of servers or any type of failures arising out
                    of technical snags or resultant inability of the Participant(s).
                </li>
                <br />
                <li>
                    The Participant's hereby agree and undertake not to hold the Organizer and/or any of their group entities or
                    affiliates, their respective directors, officers, employees, responsible for or liable for, any actions,
                    claims, demands, losses, damages, costs, charges and expenses, either direct or indirect or remote that they
                    may/might have suffered, sustained or incurred, or claim to suffer, sustain or incur, by way of and /or on
                    account of this contest.
                </li>
                <br />
                <li>
                    Any dispute arising out of this contest's T&C shall first be attempted to be resolved amicably through
                    negotiations. In the event, amicable settlement fails, the dispute shall be referred to Mediation. The venue
                    of Mediation shall be at Bengaluru only.
                </li>
                <br />
                <li>
                    This Contest and T&C are subject to Indian law and the exclusive jurisdiction of the Courts in Karnataka.
                </li>
                <br />
                <li>
                    Saffroncoin Technologies Private Limited does not endorse the products sold on the Platform and will not
                    accept any liability pertaining to the quality, merchantability, fitness, delivery or after sales service of
                    such products, which shall be at the sole liability and responsibility of the Sellers.
                </li>
                <br />
                <li>
                    This document is an electronic record in terms of Information Technology Act, 2000, and the Rules thereunder
                    as applicable and the amended provisions pertaining to electronic records in various statutes as amended by
                    the Information Technology Act, 2000. This electronic record is generated by a computer system and does not
                    require any physical or digital signatures.
                </li>
            </ol>
            <br />
            <p className="text">IMPLIED CONSENT</p>
            <p>By participating in this contest, user ("You", "Your" or "Participant") agrees to be bound by: these terms and,
                conditions ("T&C"); the Terms of Use, Privacy Policy and other relevant documentation including any
                modifications, alterations or updates thereof, that are published on the Platform.</p>
        </div>


    )
}

export default TermsAndCondition;