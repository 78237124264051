import Home from "./Home"
import Error from "../components/UI/Error";
import Welcome from "./Welcome";
import Rewards from "./Rewards";
import Landing from './Landing';

const pages = {
    Home,
    Landing,
    Welcome,
    Rewards,
    Error
}
export default pages;