import React, { useEffect, useRef, useState } from "react";
import Congratulation from './congratulation';
import quiz from '../images/quiz.png'
import timer from '../images/timer2.png';
import teamFlag1 from '../images/teamFlag1.png';
import teamFlag2 from '../images/teamFlag2.png';
import predictCheck from '../images/Checkbox.png'
import predictWrong from '../images/cancel.png';
import winSatsIcon from '../images/congratsIcon1.png';
import VS from '../images/VS.png';
import sats from '../images/sats.png';
import { MdOutlineCardGiftcard } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import { ImCircleLeft } from "react-icons/im";
import { ImCircleRight } from "react-icons/im";
import { HiCheckCircle } from "react-icons/hi";
import { BsXCircleFill } from "react-icons/bs";
import { ShimmerButton } from "react-shimmer-effects";
import * as APIHelper from '../Helper/apiFetch';
// import {claimReward} from '../Helper/mockAPIFetch';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";

const HALF_DAY = 12 * 60 * 60 * 1000;
const initiaData = require('./urlEncodeTest.json')

const shimmer = [
    {
        rows: 1,
        width: '100%',
        height: 60,
        width2: '100%',
        height2: 50,
        rows2: 1,
        width3: 120,
        height3: 105,
        height4: 20,
        width4: '100%'
    }
]

const Predict = () => {

    const [activeButton, setActiveButton] = useState(false);
    const [current, setCurrent] = useState(0)
    const [selectedTeam, setSelectedTeam] = useState(-1);
    const [shimmerLoader, setShimmerLoader] = useState(false);
    const [predectedShimmer, setPredectedShimmer] = useState(false);
    const [listTeam, setListTeam] = useState([])
    const [congratsPopup, setCongratsPopup] = useState(false);
    const [congratsTitle, setCongratsTitle] = useState(initiaData.congratulations);
    const [btnDisable, setBtnDisable] = useState(true);
    const [lockButton, setLockButton] = useState(false);
    const [selectTeam, setSelectTeam] = useState(-1);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [showPredict, setShowPredict] = useState(false);
    const [loader, setLoader] = useState(false);
    const [matchStatus, setMatchStatus] = useState("");

    const timerRef = useRef();

    const navigate = useNavigate();
    const imgLength = listTeam.length;

    useEffect(() => {
        setBtnDisable(false);
        setShimmerLoader(true);
        APIHelper.predictGetChoice().then(resData => {
            setShimmerLoader(false);
            if (resData.error) {
                navigate('/error');
            }
            if (resData?.userPredction?.choice === -1) {
                setShowPredict(true);
            }
            setListTeam(resData);
        })
        return () => {
            clearInterval(timerRef.current);
        }
    }, [])

    useEffect(() => {
        if (listTeam[current]?.result)
            setMatchStatus("ended");
        else if (Date.now() >= listTeam[current]?.date)
            setMatchStatus("started");
        else
            setMatchStatus("notstarted");
    }, [current, listTeam]);

    const startTimer = (deadline) => {
        // to switch in realtime from timer to live
        if (deadline <= Date.now()) {
            clearInterval(timerRef);
            return;
        }
        const time = deadline - Date.now();

        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    const handelSelectTeam = (choice) => {

        const alreadyPredicted = listTeam[current]?.userPrediction?.choice !== -1;
        if (alreadyPredicted || matchStatus !== "notstarted") return;
        setSelectedTeam(choice);
        setActiveButton(true);
    }

    const handelConfirmTeam = async (id, choice) => {
        setBtnDisable(true);
        setLoader(true);
        setPredectedShimmer(true);
        setSelectTeam('')
        console.log(selectTeam)
        try {
            await APIHelper.predictPickChoice(id, choice).then(resp => {
                console.log(resp);
                setBtnDisable(false);
                if (resp.error) {
                    navigate('/error');
                }
                setListTeam(matches => {
                    const currentMatch = matches[current];
                    currentMatch.userPrediction.choice = choice;
                    currentMatch.predictions = resp.predictions;
                    return matches;
                })
                setLockButton(true);
                setLoader(false);
                setActiveButton(false);
                setSelectedTeam(choice);
            })
        } catch (e) {
            console.log("Error", e);
        }

    }

    const handelClaimReward = async (match) => {
        setBtnDisable(true);
        setLoader(true);
        try {
            await  APIHelper.claimReward(match).then(resp => {
                setBtnDisable(false);
                setLoader(false);
                if (resp.error) {
                    navigate('/error');
                }
                setCongratsPopup(true);
                setListTeam(matches => {
                    const currentMatch = matches[current];
                    resp.reward.status = 2;
                    currentMatch.userPrediction['rewards'] = resp.reward;
                    currentMatch.rewardsEnabled = false;
                    return matches;
                })
                setCongratsTitle(title => {
                    if (resp.matchResult === 3)
                        return initiaData.streak.CongratsPopupDrawMatch;

                    title.reward = resp.reward.value;
                    if (resp.reward.type === "sats") {
                        title.msg1 = title.msg1?.replace("$", title.reward)
                    } else {
                        title.msg1 = title.percentageTitle?.replace("$", title.reward)
                    }
                    return title;
                })
            })
        } catch (e) {
            console.log("Error", e);
        }
    }

    const previousMatch = () => {
        setSelectedTeam(-1);
        setCurrent(current === 0 ? imgLength - 1 : current - 1);
    }

    const nextMatch = () => {
        setSelectedTeam(-1);
        setCurrent(current === imgLength - 1 ? 0 : current + 1);
    }

    const shimerLoader = () => {
        return (shimmer?.map((data, index) => {
            return (
                <div style={{height: '400px', width: 'auto'}} key={index}>
                    <div className="shimmer-effect">
                    </div>
                </div>
            )
        }
        ))
    }

    const PredShimmer = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <ShimmerButton size={{ height: 50, width: 100 }} style={{ marginBottom: '15px' }} elevation={0.1} rows='1' foreground={'#191B30'} background={'#292B47'} />
                <ShimmerButton size={{ height: 50, width: 230 }} style={{ marginBottom: '15px' }} elevation={0.1} rows='1' foreground={'#191B30'} background={'#292B47'} />
            </div>
        )
    }

    const renderPredictTitle = () => {
        if (matchStatus === "ended") {
            if (listTeam[current]?.rewardsEnabled === true) {
                if (listTeam[current]?.result === 3) {
                    return (
                        <div>The match result was a draw. Here's a consolation reward!</div>
                    )
                }
                return (
                    <div>Congratulations! You predicted right and are eligible for a reward!</div>
                )
            } else if (listTeam[current]?.userPrediction?.rewards?.status === 2) {
                return (
                    <div>You have already claimed your reward for this match. Keep predicting to win more rewards!</div>
                )
            } else if (listTeam[current].userPrediction.choice !== -1) {
                return (
                    <div>Unfortunately, your prediction was wrong. Keep predicting to win rewards!</div>
                )
            } else {
                return (
                    <div>This match is over! Keep predicting for more matches to win up to <span className="predict-subtitle-Two">10,000 Sats</span></div>
                )
            }
        } else {
            if(listTeam.length === 0 ) {
                return(
                    <div style={{ color: "#18D887" }}>
                        There is no match today!!!...
                    </div>
                )
            } else if (listTeam[current]?.userPrediction?.choice !== -1) {
                return (
                    <div style={{ color: "#18D887" }}>Your choice is confirmed! Claim rewards once the match is over.</div>
                )
            } else {
                return (
                    <div>Predict the winner of IPL matches and stand a chance to win <span className="predict-subtitle-Two">10,000 Sats</span></div>

                )
            }
        }
    }

    const ClaimButton = ({ match }) => {
        return (
            <button className="confermTeamCTA" disabled={btnDisable} onClick={() => handelClaimReward(match)} style={{ background: 'linear-gradient(92.61deg, #A50C35 16.45%, #EF442E 99.32%)', color: '#FFFFFF' }}>
                {
                    loader ?
                        <Spinner animation="border" size="sm" style={{ color: '#FFFFF', marginTop: '6px', marginBottom: '6px' }} />
                        :
                        <span><MdOutlineCardGiftcard size={24} color='#FFFFFF' />CLAIM REWARD</span>
                }
            </button>
        )
    }

    const renderConfirmButton = (userChoice) => {
        //confirm disabled
        //confirm enabled
        // confirmed locked
        if (matchStatus === "ended")
            return null;
        const alreadyPredicted = listTeam[current]?.userPrediction?.choice !== -1;
        const disabled = listTeam[current]?.userPrediction?.choice !== -1 || selectedTeam === -1 || btnDisable;
        let btnStyle = { background: "#1F4839" };
        if (alreadyPredicted)
            btnStyle = { background: '#1B2825', color: '#18D887'}
        else if (selectedTeam !== -1)
            btnStyle = { background: '#DA513B', color: '#FFFFFF'}
        else if (!alreadyPredicted)
            btnStyle = null;

        let cnfText = "CONFIRM";
        if (matchStatus !== "notstarted")
            cnfText = "PREDICTION TIME IS CLOSED"
        return (
            <button className="confermTeamCTA" disabled={disabled} onClick={() => handelConfirmTeam(listTeam[current].id, selectedTeam)}
                style={btnStyle}>
                {
                    loader ?
                        <Spinner animation="border" size="sm" style={{ color: '#FFFFF', marginTop: '6px', marginBottom: '6px' }} /> :
                        <span>{alreadyPredicted ?
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                CONFIRMED</span>
                            : <div>
                                {
                                    matchStatus !== "notstarted" ?
                                        <span><MdLockOutline size={24} color='#A6A7A8' style={{ marginRight: '10px' }} /></span>
                                        :
                                        null
                                }
                                {cnfText}
                            </div>}
                        </span>
                }
            </button>
        )
    }

    const renderTimer = () => {
        if (matchStatus === "ended") {
            if (listTeam[current]?.rewardsEnabled) {
                clearInterval(timerRef.current);
                timerRef.current = setInterval(() => startTimer(listTeam[current].date + HALF_DAY), 1000);
                return (
                    <div className="predict-timer">
                        <div className="predict-timer-title">Reward expires in: </div>
                        <img src={timer} className='predict-timer-icon' alt=" " />
                        <div className="predict-time-reminder"><span className="timerBackground">{hours}</span>:{minutes}:{seconds}</div>
                    </div>
                )
            } else {
                return (
                    <div className="predict-timer">
                        <span style={{ color: '#FFFFFF', size: '12px' }}>Match Ended</span>
                    </div>
                )
            }
        } else if (matchStatus === "started") {
            return (
                <div className="predict-timer">
                    <div className="liveDot"></div>
                    <span style={{ color: '#FB604A', size: '12px' }}>Live</span>
                </div>
            )
        } else if (matchStatus === "notstarted") {
            if (listTeam[current] && listTeam[current].date) {
                clearInterval(timerRef.current);
                timerRef.current = setInterval(() => startTimer(listTeam[current].date), 1000);
            }
            return (
                <div className="predict-timer">
                    <div className="predict-timer-title">Match starts in </div>
                    <img src={timer} className='predict-timer-icon' alt=" " />
                    <div className="predict-time-reminder"><span className="timerBackground">{hours}</span>:<span className="timerBackground">{minutes}</span>:<span className="timerBackground">{seconds}</span></div>
                </div>
            )
        }

    }
    const matchIndex = () => {
        return(
            <div className="matchCountContainer">
                MATCH {current + 1}
            </div>
        )
    }
    const MatchSelections = ({ percentages }) => {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 15px 0px 15px', color: "#A6A7A8" }}>
                    <div>{percentages[0]}</div>
                    <div>{percentages[1]}</div>
                </div>
                <div style={{ width: '92%', height: '5px',margin: '0px 15px 0px 15px', background: listTeam[current].teams[1].color }}>
                    <div style={{ width: percentages[0], height: '5px', background: listTeam[current].teams[0].color }}> </div>
                </div>
            </div>
        )
    }

    const Teams = ({ data, percentages, userChoice, alreadyPredicted }) => {
        let TeamsView;
        switch (matchStatus) {
            case "notstarted":
            case "started":
                TeamsView = data.teams.map((team, index) => (
                    <div style={{textAlign: 'center', margin: '0px 15px 0px 15px', position: 'relative'}}>
                         <div className="captainImageBg" style={{zIndex: 111, position: 'absolute'}}></div>
                        <div className="layoutGrid" style={{zIndex: 888, position: 'relative'}}>
                        </div>
                        <div style={{zIndex: 444, position: 'relative', marginTop: '-150px'}}>
                            <img src={team.captains} alt=" " className="captainsImg" />
                        </div>
                        <div key={`team${index + 1}`} className="team" onClick={() => handelSelectTeam(index + 1)}>
                            <div>
                               {/*  {
                                    userChoice === index + 1 ?
                                        <div style={{ textAlign: 'start', marginBottom: '-24px' }}>
                                            <img src={predictCheck} alt=" " style={{ width: '24px', height: 'auto', margin: '-3px 0px 0px -1px' }} />
                                        </div>
                                        :
                                        null
                                } */}
                                {
                                    <div style={{
                                        backgroundColor: "212426",
                                        border: "1px solid #A6A7A8",
                                        borderRadius: "22px",
                                        width: "20px",
                                        height: "20px",
                                        margin: "10px 10px 0px 100px"
                                    }}>
                                        {
                                            userChoice === index + 1 ?
                                            <img src={predictCheck} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px'}} />
                                            :
                                            null
                                        }
                                    </div>
                                }
                                {/* <div className="teamFlagInnerShadow"
                                style={Object.assign(
                                    {},
                                    userChoice === index + 1 ? { background: '#471C85', boxShadow: '6px 6px 12px 0px rgba(0, 0, 0, 0.18) inset, -6px -6px 12px 0px rgba(255, 255, 255, 0.08) inset;' } : null,
                                )}
                                 >
                                <img className="teamFlag" alt="" src={data.teams[index].image} />
                                </div> */}
                                <img className="teamFlag" alt="" src={data.teams[index].image} />
                                <div className="teamTitle">{data.teams[index].name}</div>
                            </div>
                        </div>
                    </div>
                ));

                break;
            case "ended":
                TeamsView = data.teams.map((team, index) => (
                    <div style={{ textAlign: 'center', margin: '0px 15px 0px 15px', position: 'relative'}}>
                        <div className="captainImageBg" style={{zIndex: 111, position: 'absolute'}}></div>
                        <div className="layoutGrid" style={{zIndex: 888, position: 'relative'}}>
                        </div>
                        <div style={{zIndex: 444, position: 'relative', marginTop: '-150px'}}>
                            <img src={team.captains} alt=" " className="captainsImg" />
                        </div>
                        <div key={`team${index + 1}`} className="team" onClick={() => handelSelectTeam(index + 1)}>
                            <div>
                                {
                                    <div style={{
                                        backgroundColor: "212426",
                                        border: "1px solid #A6A7A8",
                                        borderRadius: "22px",
                                        width: "20px",
                                        height: "20px",
                                        margin: "10px 10px 0px 100px"
                                    }}>
                                       {/*  {   userChoice === -1 ?
                                                null 
                                                :
                                                userChoice === index + 1 ?
                                                userChoice !== listTeam[current].result ?
                                                    <img src={predictWrong} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                                    :
                                                    null
                                                :
                                                <img src={predictCheck} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                        } */}
                                        {userChoice === -1 ?
                                            listTeam[current].result === index + 1 ?
                                                <img src={predictCheck} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                                :
                                                null
                                            :
                                            userChoice === index + 1 ?
                                                userChoice !== listTeam[current].result ?
                                                    <img src={predictWrong} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                                    :
                                                    null
                                                :
                                                <img src={predictCheck} alt=" " style={{ width: '20px', height: '20px', margin: '-9px 0px 0px -1px' }} />
                                        }
                                    </div>
                                }
                                <img className="teamFlag" alt="" src={data.teams[index].image} />
                                <div className="teamTitle">{data.teams[index].name}</div>
                            </div>
                        </div>
                    </div>
                ));
                break;
            default:
                TeamsView = null;
        }
        TeamsView.splice(1, 0,<div key='vs' className="vs"><img className="vsImg" src={VS} alt=" " /></div>)


        return (
            <div>
                <div key={data.id}>
                    <div className="team-container">
                        {TeamsView}
                    </div>
                </div>
                    {alreadyPredicted || matchStatus !== "notstarted" ?
                        <MatchSelections percentages={percentages} />
                        : null}
                {data.userPrediction?.rewards?.status === 2 ?
                    <div className='climedReward-container'>
                        <div className='winSatsIcon-container'>
                            <img src={winSatsIcon} className='winSatsIcon' alt=' ' />
                        </div>
                        {data.userPrediction?.rewards?.type === "sats" ?
                            <div className="rewardWonTitle">
                                <div> You have claimed your reward! <img src={sats} width='15px' height='auto' />{data.userPrediction?.rewards?.value}</div>
                            </div>
                            :
                            <div className="rewardWonTitle">
                                <span> You’ve won <span>{data.userPrediction?.rewards?.value}</span>% extra Bitcoin reward on your next transaction.</span>
                            </div>
                        }

                    </div>
                    : null}
            </div>
        )
    }

    const predictTeam = () => {
        return (
            <div>
                {
                    listTeam?.map((data, index) => {
                        const alreadyPredicted = data.userPrediction?.choice !== -1;
                        const userChoice = alreadyPredicted ? data.userPrediction?.choice : selectedTeam;
                        let percentages;
                        if (data.predictions === undefined)
                            percentages = ['100%', '0%'];
                        else {
                            percentages = [Math.floor(((data.predictions[0]) / (data.predictions[0] + data.predictions[1])) * 100)];
                            percentages.push(100 - percentages[0]);
                            percentages = percentages.map(p => p + '%');
                        }
                        return index === current ?
                            <Teams key={data.id} index={index} alreadyPredicted={alreadyPredicted} data={data} percentages={percentages} userChoice={userChoice} />
                            : null;
                    })
                }
                <div className="predict-subtitle">
                            {
                                renderPredictTitle()
                            }
                        </div>
                {
                    listTeam.length !== 0 ?
                        <div className="confermTeamCTA-container">
                            {listTeam[current]?.rewardsEnabled ?
                                <ClaimButton match={listTeam[current]?.id} />
                                :
                                renderConfirmButton()
                            }
                        </div>
                        :
                        null
                }
                <div className="pagination-container">
                    <div>
                        <button className="backward-arrow-container" disabled={current + 1 <= 1 || btnDisable} onClick={previousMatch}><ImCircleLeft size={24} color={current + 1 <= 1 ? "#343739" : "#FFFFFF"} /></button>
                    </div>
                    <div className="pagination-count"> {current + 1}/{listTeam.length} </div>
                    <div>
                        <button className="backward-arrow-container" disabled={current + 1 >= imgLength || btnDisable} onClick={nextMatch}><ImCircleRight size={24} color={current + 1 >= imgLength ? "#343739" : "#FFFFFF"} /></button>
                    </div>
                </div>
            </div>
        )
    }

    const predictedTeam = () => {
        return (
            <div>
                {!predectedShimmer ?
                    <div>
                        <PredShimmer />
                    </div>
                    :
                    selectTeam === 1 ?

                        <div className="selectedTeamContainer">
                            <div style={{ paddingRight: '15px' }}>
                                <img className="teamFlag " alt="" src={teamFlag1} />
                            </div>
                            <div className="selectedTeamTitle">You’ve predictied India as a winner of T20 World cup.</div>
                        </div>
                        :
                        <div className="selectedTeamContainer">
                            <div style={{ paddingRight: '15px' }}>
                                <img className="teamFlag " alt="" src={teamFlag2} />
                            </div>
                            <div className="selectedTeamTitle">You’ve predictied Pakistan as a winner of T20 World cup.</div>
                        </div>
                }
            </div>
        )
    }

    return (
        <div className='purchaseStreak-sectionOne'>
            {
                shimmerLoader ? shimerLoader() :
                    <div>
                        <div className="predictTimerCountContainer">
                            <div>
                                {matchIndex()}
                            </div>
                            <div>
                                {renderTimer()}
                            </div>
                        </div>
                        {/* <div className="predict-subtitle">
                            {
                                renderPredictTitle()
                            }
                        </div> */}
                        <div>
                            {
                                !showPredict ?
                                    predictTeam()
                                    :
                                    null
                                    // predictedTeam()
                            }
                        </div>
                    </div>
            }
            <div>
                {congratsPopup && <Congratulation title={congratsTitle} />}
            </div>
        </div >
    );
}

export default Predict;


