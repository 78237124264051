import React from "react";

const MatchSelections = ({ percentages, teamColors }) => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 15px 0px 15px', color: "#A6A7A8" }}>
                <div>{percentages[0]}</div>
                <div>{percentages[1]}</div>
            </div>
            <div style={{ width: '92%', height: '5px', margin: '0px 15px 0px 15px', background: teamColors[1] }}>
                <div style={{ width: percentages[0], height: '5px', background: teamColors[0] }}> </div>
            </div>
        </div>
    );
};

const UserPrediction = ({ match }) => {
    let percentages = [];

    if (match.predictions === undefined) {
        percentages = ['100%', '0%'];
    } else {
        const totalPredictions = match.predictions.reduce((sum, prediction) => sum + prediction, 0);
        const firstTeamPercentage = Math.floor((match.predictions[0] / totalPredictions) * 100);
        percentages = [firstTeamPercentage + '%', 100 - firstTeamPercentage + '%'];
    }

    const teamColors = [match.teams[0].color, match.teams[1].color];

    return (
        <MatchSelections percentages={percentages} teamColors={teamColors} />
    );
};

export default UserPrediction;
