import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

const FAQ = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return (
        <div className='valentineFaqContainer'>
            <div className="accordion-title d-flex justify-content-between align-items-center" onClick={() => setIsActive(!isActive)}>
                <div className='faq-container d-flex'>
                    <span className='faqTitle'>FAQs</span>
                </div>
                <div className="col-1 d-flex justify-content-end"><span className="row toggleBtn">{isActive ? <FaAngleUp /> : <FaAngleDown />}</span></div>
            </div>
            {isActive && <div className="accordion-content subText">
                <div>
                    <ul className='termAndConditionPoints mt-4'>
                        <li>
                            <p className='FAQ-Question'>Who is eligible for the GoSats Valentine's Week Offer: Boosted Rewards on Gift Voucher Purchase?</p>
                            <p className='paragraph'>The offer is applicable only to users who purchase Gift Vouchers from select brands during the offer period.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>When is the valid period for the Boosted Rewards on Gift Voucher Purchase offer?</p>
                            <p className='paragraph'>The offer is valid only from 10th February 2024 to 14th February 2024.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Which brands are eligible for boosted rewards on Gift Voucher purchases?</p>
                            <p className='paragraph'>The boosted rewards are applicable to Gift Vouchers from the following brands: i) Nykaa ii) Myntra iii) Swiggy iv) Zomato v) MakeMyTrip vi) Starbucks vii) Blue Tokai viii) PVR.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'> How much rewards value will users receive on eligible Gift Voucher purchases?</p>
                            <p className='paragraph'>All eligible Gift Voucher purchases will receive 10% rewards on the purchase value.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>In what form will the rewards be provided?</p>
                            <p className='paragraph'>The rewards will be in the form of Sats or Gold, depending on the reward type set by the user prior to the purchase of the card.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Can the offer be combined with other discount codes or promotions?</p>
                            <p className='paragraph'>No, the offer cannot be combined or clubbed with any other discount codes or promotions.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>How are disputes handled in connection with the offer?</p>
                            <p className='paragraph'>Disputes arising in connection with the offer shall be subject to the jurisdiction of the courts in Bangalore.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Can GoSats modify or cancel the offer, and are there any disqualification criteria?</p>
                            <p className='paragraph'>GoSats reserves the right to modify or cancel the offer at any time without prior notice. Users may be disqualified if there is suspicion of fraudulent activities or violation of the terms and conditions.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>What laws govern the offer, and what are the KYC requirements?</p>
                            <p className='paragraph'>The offer is subject to applicable laws, and users are required to comply with all KYC and verification requirements as per GoSats's policies and Indian regulations.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>How can users stay informed about changes to the offer's terms and conditions?</p>
                            <p className='paragraph'>Users are advised to refer to the official GoSats website or communication channels for the most up-to-date information on the offer.</p>
                        </li>
                    </ul>
                </div>
            </div>}
        </div>
    );
}

export default FAQ;