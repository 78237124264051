import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

const FAQ = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return (
        <div className='valentineFaqContainer'>
            <div className="accordion-title d-flex justify-content-between align-items-center" onClick={() => setIsActive(!isActive)}>
                <div className='faq-container d-flex'>
                    <span className='faqTitle'>FAQs</span>
                </div>
                <div className="col-1 d-flex justify-content-end"><span className="row toggleBtn">{isActive ? <FaAngleUp /> : <FaAngleDown />}</span></div>
            </div>
            {isActive && <div className="accordion-content subText">
                <div>
                    <ul className='termAndConditionPoints mt-4'>
                        <li>
                            <p className='FAQ-Question'>Who is eligible for the GoSats Valentine's Week Offer: Card Purchase and Upgrade?</p>
                            <p className='paragraph'>The offer is applicable to users who either purchase a GoSats Elite Card or upgrade their Intro Card to an Elite Card during the specified offer period.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>What is the pricing and validity of the GoSats Elite Card during the offer period?</p>
                            <p className='paragraph'>The GoSats Elite Card will be available at Rs. 499 with a lifetime validity during the offer period.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'> What is the pricing and validity of the GoSats Intro Card during the offer period?</p>
                            <p className='paragraph'>The GoSats Intro Card will be available at Rs. 99 with a lifetime validity during the offer period.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Can Intro Card users upgrade to an Elite Card, and what is the cost during the offer period?</p>
                            <p className='paragraph'>Yes, GoSats Intro Card users can upgrade to an Elite Card by paying Rs. 399 during the offer period, and the Elite Card will have a lifetime validity.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>When is the offer period for the GoSats Valentine's Week Card Purchase and Upgrade offer?</p>
                            <p className='paragraph'>The offer is applicable only from 10th February 2024 to 14th February 2024.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'> How many times can a user avail of the discount offer during the offer period?</p>
                            <p className='paragraph'>The discount offer can be availed only once per user.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Can the offer be combined with other discount codes or promotions?</p>
                            <p className='paragraph'>No, the offer cannot be combined or clubbed with any other discount codes or promotions.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>How are disputes handled in connection with the offer?</p>
                            <p className='paragraph'>Disputes arising in connection with the offer shall be subject to the jurisdiction of the courts in Bangalore.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>Can GoSats modify or cancel the offer, and are there any disqualification criteria?</p>
                            <p className='paragraph'>GoSats reserves the right to modify or cancel the offer at any time without prior notice. Users may be disqualified if there is suspicion of fraudulent activities or violation of the terms and conditions.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>What laws govern the offer, and what are the KYC requirements?</p>
                            <p className='paragraph'>The offer is subject to applicable laws, and users are required to comply with all KYC and verification requirements as per GoSats's policies and Indian regulations.</p>
                        </li>
                        <li>
                            <p className='FAQ-Question'>How can users stay informed about changes to the offer's terms and conditions?</p>
                            <p className='paragraph'>Users are advised to refer to the official GoSats website or communication channels for the most up-to-date information on the offer.</p>
                        </li>
                    </ul>
                </div>
            </div>}
        </div>
    );
}

export default FAQ;