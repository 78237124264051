import { BrowserRouter as BRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Pages from "./pages";

import LandingScreen from "./cc/LandingScreen";
import InputPhoneNum from "./cc/InputPhoneNum";
import VerifyOtp from "./cc/verifyOtp";
import OlduserError from "./cc/OlduserError";
import WebError from "./cc/WebError";
import Congrats from "../src/cc/CongratsScreen";
import KitAssignedAlready from "../src/cc/KitAssignedAlready";

import CardUser from "../src/RepublicCamp/CardUser";
import NonCardUser from "../src/RepublicCamp/NonCardUser";
import TermsAndCondition from "./pages/TermsAndCondition";
import WorldCupFAQ from '../src/pages/WorldCupFAQ';

import Wazirx from "./components/Wazirx";
import WazirxTAndC from "./components/WazirxT&C";

import Quiz from "./components/quiz";

import ValentineLandingScreen from "./ValentineDayCampaign";
import RewardStore from "./RewardStore";

function App() {

  return (
    <div className="App">
      <BRouter>
        <Routes>
          {/*Republic Day Campaign */}
          <Route path="/card/:type" element={<CardUser />} />
          <Route path="/noncard" element={<NonCardUser />} />
          {/* Christmas Campaign Routes */}
          <Route path="/" element={<Pages.Landing />} />
          <Route path="/home" element={<Pages.Home />} />
          <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
          <Route path="/welcome" element={<Pages.Welcome />} />
          <Route path="/rewards" element={<Pages.Rewards />} />
          <Route path="/error" element={<Pages.Error />} />
          {/* Card Campaign Routes */}
          <Route path="/cc/:id" element={<LandingScreen />} />
          <Route path="/cc/inputPhoneNum" element={<InputPhoneNum />} />
          <Route path="/cc/verifyOtp" element={<VerifyOtp />} />
          <Route path="/cc/olduserError" element={<OlduserError />} />
          <Route path="/cc/congrats" element={<Congrats />} />
          <Route path="/cc/error" element={<WebError />} />
          <Route path="/cc/kitassignedalready" element={<KitAssignedAlready />} />
          {/* Common Routes */}
          <Route path="/error" element={<Pages.Error />} />
          {/* Wazirx*/}
          <Route path="/partnership/wazirx" element={<Wazirx />} />
          <Route path="/partnership/WazirxTAndC" element={<WazirxTAndC />} />
          {/* Test */}
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/faq" element={<WorldCupFAQ />} />
          {/* Valentine’s Day Campaign */}
          <Route path="/valentine/2024/:type" element={<ValentineLandingScreen />} />
          <Route path="/rewardStore" element={<RewardStore />} />
        </Routes>
      </BRouter>
    </div>
  );
}

export default App;
