import * as nativeEvents from '../Helper/native';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import FTUserImg from '../images/FTUserImg.png';
import { FiChevronLeft } from "react-icons/fi";
import * as APIHelper from '../Helper/apiFetch';

export default function Welcome() {
    const navigate = useNavigate();

    return (
        <div className="landeingIndex">
            <button className='back-button' onClick={nativeEvents.goBack}>
                <FiChevronLeft className='back-button-icon' />
            </button>
            <div className='log-container'>
                <div style={{ position: 'relative' }}>
                    <img className='logo' src={logo} alt=" " />
                    <div className='head-title'>BITCOIN SANTA</div>
                    <div>
                        <img alt=" " src={FTUserImg} className='FTUserImg' />
                    </div>
                </div>
                <div className='FTUserSubtitle'>
                    Let's celebrate the season of giving together, with mystery rewards and Bitcoin rewards for the highest stacker everyday
                </div>
                <div style={{ padding: '20px', position: 'absolute', bottom: 0, width: '90%' }}>
                    <button className='FTUNextBtn' onClick={() => {
                        APIHelper.postAnalytics("onboarding_page");
                        navigate('/home')
                    }}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}
