import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ShimmerButton } from "react-shimmer-effects";
import { MdOutlineCardGiftcard } from "react-icons/md";
import sats from '../images/sats.png';
import timer from '../images/timer.png';
import iplStreak from '../images/IPL streak.png';
import congratsIcon from '../images/congratsIcon.png';
import rewardBg from '../images/rewardClaimBg.png';
// import * as APIHelper from '../Helper/mockAPIFetch';
import * as APIHelper from '../Helper/apiFetch';
import { HiOutlineShoppingBag } from "react-icons/hi";
// import ActiveDays from './activeDays';
import ActiveDays from './ActiveDays';
import Congratulation from '../components/congratulation';
import Button from '../components/UI/Button';
import Lottie from "lottie-react";
import CongLottie from '../../src/conglottie.json';
// import Notify from './notify';
import moment from 'moment';
// import Predict from './predict';
import { ClipLoader } from 'react-spinners';
import * as nativeEvents from '../Helper/native';
import Predict from '../components/predict';
import Notify from '../components/notify';

const initiaData = require('../components/urlEncodeTest.json')

const shimmer = [
    {
        rows: 1,
        width: 150,
        height: 45,
        width2: 100,
        height2: 105,
        rows2: 1,
        width3: '100%',
        height3: 105,
        width4: 200
    }
]


function calculateHMleft(endTime) {
    //calculate
    let hoursleft = 23 - endTime.getHours();
    let minutesleft = 60 - endTime.getMinutes();
    const secsLeft = 59 - endTime.getSeconds();

    //format 0 prefixes
    if (hoursleft < 10) hoursleft = "0" + hoursleft;
    if (minutesleft < 10) minutesleft = "0" + minutesleft;

    //display
    return [hoursleft, minutesleft, secsLeft];
}
const PurchaseStreak = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDay, setActiveDay] = useState(initiaData?.streak.data);
    const [btnDisable, setBtnDisable] = useState(false);
    const [bgcolor, setbgcolor] = useState("");
    const [rewardClimed, setRewardClimed] = useState(false);
    const [congratsPopup, setCongratsPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [streakHidden, setStreakHidden] = useState(false);
    const [time, setTime] = useState(" ");
    const [predict, setPredict] = useState(false);
    const [shopTimer, setShopTimer] = useState(false);
    const [rewardTimer, setRewardTimer] = useState(false);
    const [reward, setReward] = useState(undefined);
    const [congratsTitle, setCongratsTitle] = useState(initiaData.streak.CongratsPopupWeeklyStreak);
    const [weeklySteak, setWeeklySteak] = useState(initiaData?.streak.purchaseStreakDetails)
    const [rewardSats, setRewardSats] = useState('');
    const [climeButton, setClimeButton] = useState(false);
    const [message, setMessage] = useState(initiaData.popUpShareMessage.msg)
    // const weeklySteak = initiaData?.streak.purchaseStreakDetails;
    const alreadyClimed = initiaData?.streak.alreadyClimedReward;
    const climed = initiaData?.streak.isClaimed;

    // const statusNotify = initiaData?.notify.isNotifyActive.status;

    const navigate = useNavigate();

    useEffect(() => {
        setLoader(true);
        APIHelper.streakInfo().then(resData => {
            console.log(resData)
            if (resData.error) {
                navigate('/error');
                return;
            } else {

                setLoader(false);
                const streakData = resData.streak ? resData.streak : resData;
                setWeeklySteak(title => {
                    title.sats = resData.potentialReward;
                    title.msg1 = title.msg1?.replace("$", title.sats);
                    return title;
                });
                setReward(resData.rewardsClaimed);
                setStreakHidden(resData.streakHidden);
                setRewardSats(resData.rewardsClaimed);
                setRewardClimed(resData.rewardsEnabled);
                setPredict(resData.predict);
                setActiveDay(streakData);
                if (resData.rewardsEnabled) {
                    setRewardTimer(true);
                    startRewardTimer(resData.lastPurchaseMade + 24 * 60 * 60 * 1000)
                }
                else if (streakData[0].isDay && streakData?.find((d, i) => i !== 0 && d.day === moment().format('dd').toUpperCase() && !d.isDay)) {
                    setShopTimer(true);
                    updateTimer();
                }
                // setPredict(resData.showFinals)
            }
        })
    }, [])



    useEffect(() => {
        setbgcolor(weeklySteak?.bgColor)
    }, [weeklySteak])


    const timerRef = useRef();


    const updateTimer = () => {
        const update = () => {
            const currentTime = new Date();
            const midnight = new Date(currentTime);
            midnight.setHours(24, 0, 0, 0); // Set to 12:00 AM (midnight)

            const timeDiff = Math.max(midnight - currentTime, 0); // Ensure the timer does not go negative

            const hoursLeft = Math.floor(timeDiff / 3600000); // 1 hour = 3600000 milliseconds
            const minutesLeft = Math.floor((timeDiff % 3600000) / 60000); // 1 minute = 60000 milliseconds
            const secsLeft = Math.floor((timeDiff % 60000) / 1000); // 1 second = 1000 milliseconds

            const timerJSX = (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                    <span className="timerBG">{hoursLeft}</span>:
                    <span className="timerBG">{minutesLeft}</span>:
                    <span className="timerBG">{secsLeft}</span>
                </div>
            );

            setTime(timerJSX);
        };

        // Call the update function immediately
        update();

        // Schedule the update function to run every second
        timerRef.current = setInterval(update, 1000);
    };


    const startRewardTimer = (dl) => {
        const updateTimer = () => {
            const rem = dl - Date.now();
            if (rem <= 0) {
                // Timer has expired, you can handle this case here
                clearTimeout(timerRef.current);
                setTime("00:00:00");
            } else {
                const hoursleft = Math.floor(rem / (60 * 60 * 1000));
                const minutesleft = Math.floor((rem % (60 * 60 * 1000)) / 60000);
                const secsLeft = Math.floor((rem % 60000) / 1000);

                const timerJSX = (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                        <span className="timerBG">{hoursleft}</span>:
                        <span className="timerBG">{minutesleft}</span>:
                        <span className="timerBG">{secsLeft}</span>
                    </div>
                );
                setTime(timerJSX);
            }
        };

        // Initial call to updateTimer to set the initial time
        updateTimer();

        // Set up an interval to update the timer every second
        timerRef.current = setInterval(updateTimer, 1000);
    };

    useEffect(() => {
        return () => {
            clearInterval(timerRef.current);
        }

    }, [])

    const claimReward = () => {
        setClimeButton(true);
        setBtnDisable(true);
        /* APIHelper.streakClaimReward().then(resData => {
           console.log(resData);
           setClimeButton(false);
           if (resData.error) {
               navigate('/error');
               return;
           } else {
               setCongratsPopup(true);
               setRewardClimed(resData.success);
               setReward(resData.success)
               setRewardSats(resData.rewardsClaimed);
               setCongratsTitle(title => {
                   title.reward = resData.rewardsClaimed;
                   title.msg1 = title.msg1?.replace("$", title.reward)
                   return title;
               });

           }
       })  */
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: CongLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const shimerLoader = () => {
        return (shimmer?.map((data, index) => {
            return (
                <div key={index}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <ShimmerButton size={{ height: data?.height, width: data?.width }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows} foreground={'#191B30'} background={'#292B47'} />
                            <ShimmerButton size={{ height: data?.height, width: data?.width4 }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows} foreground={'#191B30'} background={'#292B47'} />
                        </div>
                        <ShimmerButton size={{ height: data?.height2, width: data?.width2 }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows2} foreground={'#191B30'} background={'#292B47'} />
                    </div>
                    <ShimmerButton size={{ height: data?.height3, width: data?.width3 }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows2} foreground={'#191B30'} background={'#292B47'} />
                </div>
            )
        }
        ))
    }

    const alreadyClimedReward = () => {
        return (
            <div className='climedReward-container'>
                <Lottie
                    options={defaultOptions}
                    height={70}
                    width={70}
                />
                <div className='winSats-Title'>{alreadyClimed.climedReward.climedTitle} <span className='rewardSats' style={{ color: '#FFE7B7' }}><img className='rewardSats-icon' src={sats} alt=' ' />{rewardSats}</span></div>
                <div>
                    <img src={rewardBg} alt='' className='climeCardBg' style={{ margin: "-6px -10px -6px 0px" }} />
                </div>
            </div>
        )
    }

    const contentDisplay = () => {
        return (
            <div style={{ position: 'relative', paddingTop: '10px' }}>
                <div className='d-flex justify-content-between'>
                    <div>
                        {
                            reward != undefined ?
                                null
                                :
                                <div className='purchaseStreakButton-container'>
                                    {(rewardTimer || shopTimer) && <div className='purchaseStreak-timer-container'>
                                        <div className='purchaseStreak-timer-title-container'>
                                            {
                                                rewardTimer &&
                                                <div className='streakTimerContainer'>
                                                    <div className='purchaseStreak-timer-title'>Your reward will expire in</div>
                                                    <img className='backConfig-timer-icon' src={timer} alt=' ' />
                                                    <div className='purchaseStreak-timer'>{time}</div>
                                                </div>
                                            }
                                            {
                                                shopTimer &&
                                                <div className='streakTimerContainer'>
                                                    <div className='purchaseStreak-timer-title'>Streak will reset in</div>
                                                    <img className='backConfig-timer-icon' src={timer} alt=' ' />
                                                    <div className='purchaseStreak-timer'>{time}</div>
                                                </div>
                                            }

                                        </div>
                                    </div>}
                                </div>
                        }
                        <div>
                            <img className='IPLStreakImg' src={iplStreak} alt=' ' />
                        </div>
                        <div className='rectangleBg'>
                            <div className='purchaseStreakTitle'>{weeklySteak.title}</div>
                            {/* {!rewardTimer ?
                                <div className='purchaseStreakTitle'>{weeklySteak.title}</div>
                                :
                                <div className='purchaseStreakTitle'>Yay! You’ve <br />completed the 7-day streak!</div>
                            } */}
                        </div>
                        <div>
                            {!rewardTimer ?
                                <div>
                                    {
                                        !rewardClimed ?
                                            <p className='purchaseStreakSubTitle' style={{ marginBottom: '-10px' }}>{reward !== undefined ? reward !== undefined ? climed?.details?.msg1 : weeklySteak.msg1 : weeklySteak.msg1}</p>
                                            :
                                            <p className='purchaseStreakSubTitle'>{alreadyClimed.climedReward.msg1}</p>
                                    }
                                </div>
                                :
                                <div style={{ color: '#A6A7A8', marginTop: '12px', fontSize: '14px' }}>
                                    <div>{weeklySteak.msg1}</div>
                                    <div className='purchaseStreakSubTitle' style={{ color: '#18D887' }}>You have successfully completed your streak!</div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <div>
                    {
                        reward != undefined ? null
                            :
                            <div className='d-flex justify-content-between weekDays'>
                                {
                                    activeDay && activeDay?.map((obj, index) => {
                                        return <ActiveDays key={index} activeDay={obj} />
                                    })
                                }
                            </div>
                    }
                </div>

                <div>
                    {
                        reward != undefined ?
                            <div className='mt-4'>
                                {alreadyClimedReward()}
                            </div>
                            :
                            <div className='purchaseStreakButton-container'>
                                {!rewardTimer ?
                                    (<Button classNames={'shopButton'}
                                        onClick={nativeEvents.shopNow}
                                        buttonIcon={<HiOutlineShoppingBag className='mb-1' size={24} style={{ marginRight: '9px' }} />}
                                        buttonTitle={weeklySteak.buttonTitle}>
                                    </Button>)
                                    : (
                                        <Button classNames={'shopButton'}
                                            disabled={btnDisable}
                                            onClick={claimReward}
                                            buttonIcon={climeButton ?
                                                <div style={{ marginRight: "10px", display: "flex", justifyContent: "center" }}>
                                                    <ClipLoader color="#FFFFFF" size={17} />
                                                </div>
                                                :
                                                <MdOutlineCardGiftcard className='mb-1' size={24} style={{ marginRight: '9px' }} />}
                                            buttonTitle={climed.details.buttonTitle}>
                                        </Button>
                                    )
                                }

                            </div>
                    }
                </div>
            </div>
        )
    }


    {/* return (
        <div>
            {
                streakHidden ? null
                    :
                    <div className=' purchaseStreak-container'>
                        <div>
                            {
                                predict ?
                                    <div>
                                        <Predict />
                                    </div>
                                    :
                                    <div className=' purchaseStreak-sectionOne' style={{ backgroundColor: bgcolor, marginTop: "30px" }}>
                                        {loader ? shimerLoader() : contentDisplay()}
                                    </div>
                            }
                        </div>
                        <div>
                            {congratsPopup && <Congratulation title={congratsTitle} message={message} />}
                        </div>
                    </div>
            }
        </div>
    ); */}

    return (
        <div className=' purchaseStreak-container'>
            {
                streakHidden ?
                    <div>
                        {
                            !predict ?
                                <div>
                                    <Predict />
                                </div>
                                :
                                <div>
                                    <Notify />
                                </div>

                        }
                    </div>
                    :
                    <div>
                        <div className=' purchaseStreak-sectionOne' style={{ backgroundColor: bgcolor, marginTop: "30px" }}>
                            {loader ? shimerLoader() : contentDisplay()}
                        </div>
                        <div>
                            {congratsPopup && <Congratulation title={congratsTitle} message={message} />}
                        </div>
                    </div>

            }
        </div>
    );
}

export default PurchaseStreak;