import { useState, useEffect } from "react";
import * as nativeEvents from "../../Helper/native";
import Card from '../UI/Card';
import PopupCongratsTitle from "../UI/PopupCongratsTitle";
import { FiChevronLeft } from "react-icons/fi";
import ConfettiComp from "../UI/Confetti";
import BackButton from "../UI/BackButton";
import Modal from 'react-modal';
Modal.setAppElement("#root");

export default function ReferralBonus(props) {

    var { reward, code, link } = props;

    const [modal, setModal] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setModal(false);
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-model')
            document.body.style.overflow = 'hidden'
        } else {
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [modal])

    const close = () => {
        setIsOpen(false);
    }


    return (
        <div>
            {
                modal && (
                    <Modal
                        isOpen={isOpen}
                        closeTimeoutMS={500}
                        className="mymodal"
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                    >
                        <ConfettiComp />
                        <div>
                            <div className="popupBackButtonContainer">
                                <button className='back-button' onClick={close}>
                                    <FiChevronLeft className='back-button-icon' />
                                </button>
                            </div>
                            <div style={{ color: '#FFFFFF', height: '100vh', paddingTop: '10%' }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Card title={reward} />
                                    </div>
                                    <div style={{ color: '#A6A7A8', fontSize: '14px', fontWeight: 400, margin: '40px 20px 0px 20px' }}>
                                        <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 700 }}>
                                            HOW REFERAL REWARDS WORK:
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <ul>
                                                <li>
                                                    Refer your friends to GoSats.
                                                </li>
                                                <li>
                                                    Get them to complete a voucher purchase
                                                </li>
                                                <li>
                                                    Voila! You both just earned 2x the usual referral rewards, ie, 10,000 Sats!
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="popUpButtonContainer">
                                        <button onClick={() => {
                                            nativeEvents.shareReferral(code, link)
                                        }} className="popUpButton">
                                            GIFT NOW
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    )
} 