//API Base URL
const API_ROOT = 'https://api.gosats.io/v1/campaign';

const PP_API_ROOT = 'https://api.gosats.io/v1';

const REWARD_STORE = 'https://devapi.gosats.io/v1/rewardstore/dp'

//API Paths
const API_URLS = {
    SIGN_IN: '/auth/user/signin',
    OTP_VERIFY: '/auth/user/verify/phone/campaign',
    TOP_FIVE: '/leaderboard/top',
    PURCHASE_STREAK: '/streak',
    REFRESH_TOKEN: '/token/renew',
    STREAK_CLAIM_REWARD: '/streak/reward',
    CLAIM_REWARD: '/match/reward',
    DAILY_QUIZ: '/quiz',
    START_QUIZ: '/quiz/start',
    SUBMIT_QUIZ: '/quiz/submit',
    GET_CHOICE: '/match',
    PICK_CHOICE: '/match/predict',
    GET_NOTIFICATION: '/notification',
    SET_NOTIFICATION: '/notification/notify',
    TOPSTACKER_CONGRATULATION_POPUP: '/leaderboard/winner/popup',
    IS_NEWUSER: '/welcome',
    GET_GIFTS_CARDS: '/gifts',
    CLAIM_GIFT_CARD: '/gifts/claim',
    PREV_WINNERS: '/leaderboard/hof',
    POST_ANALYTICS: '/analytics',

    //Rewward Store
    CHECK_BAL: '/user/wallet/balance',
    ORDER_CONFIRM: '/user/order/confirm',
    ORDER_CREATE: '/user/order/create',
    ORDER_PROCESS: '/user/order/processed',
    ORDER_STATUS_CHECK: '/user/check/order/status',
    CHECK_REWARD_VALUE: '/get/reward/value'
}
const AUTH_TYPE = {
    NO_AUTH: 0,
    TOKEN: 1,
    REFRESH: 2,
    ACCESSKEY: 3
}

//Common API caller
const commonApiCall = async (apiURL, reqMethod, authType, bodyParm) => {
    let headerData = {};
    //Check the header
    switch (authType) {
        case AUTH_TYPE.NO_AUTH:
            headerData = { 'Content-type': 'application/json' };
            break;
        case AUTH_TYPE.TOKEN:
            headerData = {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${await sessionStorage.getItem('AccessToken')}`,
            };
            break;
        case AUTH_TYPE.REFRESH:
            headerData = {
                'Content-type': 'application/json',
                'Authorization': sessionStorage.getItem('RefreshToken')
            };
            break;
        case AUTH_TYPE.ACCESSKEY:
            headerData = {
                'Content-type': 'application/json',
                'Authorization': bodyParm.hash
            }
            break;
        default: headerData = {};
    }

    return new Promise((resolve, reject) => {
        try {
            var methodData = reqMethod ? reqMethod : 'GET'
            var bodyData = bodyParm ? JSON.stringify(bodyParm) : null
            fetch(apiURL, {
                method: methodData,
                headers: headerData,
                body: bodyData
            })
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.json());
                    } 
                    else if (response?.status === 405 || response?.errorCode === "E0004" ) {
                        const refreshToken = sessionStorage.getItem('RefreshToken');
                        if (refreshToken) {
                            refresh().then(resData => {
                                sessionStorage.setItem('AccessToken', (resData?.data?.AccessToken));
                                commonApiCall(apiURL, reqMethod, authType, bodyParm).then(resp => {
                                    resolve(resp);
                                })
                            })
                        } else {
                            window.location.href = "/error";
                        }
                    }
                    else {
                        window.location.href = "/error";
                    }
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
        } catch (e) {
            reject(e)
        }
    })
}

//Post Phone Number
export async function signIn(phoneNumber: string) {
    const apiUrl = `${PP_API_ROOT}${API_URLS.SIGN_IN}`;
    const requestObj = {
        phoneNumber: `+91${phoneNumber}`
    };
    return await commonApiCall(apiUrl, 'POST', AUTH_TYPE.NO_AUTH, requestObj);
}

//OTP verify
export async function otpVerfy(phoneNumber: string, otp: number, kitNum: string) {
    const apiUrl = `${PP_API_ROOT}${API_URLS.OTP_VERIFY}`;
    const requestObj = {
        phoneNumber: `+91${phoneNumber}`,
        code: otp,
        kitNum: kitNum
    };
    return await commonApiCall(apiUrl, 'POST', AUTH_TYPE.NO_AUTH, requestObj);
}

export async function authCheck() {
    const apiURL = `${API_ROOT}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function refresh() {
    const apiURL = `${PP_API_ROOT}${API_URLS.REFRESH_TOKEN}`;
    const requestObj = false;
    return await commonApiCall(apiURL, 'POST', AUTH_TYPE.REFRESH, requestObj)
}

export async function getNotification() {
    const apiURL = `${API_ROOT}${API_URLS.GET_NOTIFICATION}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

/* export async function setNotification() {
    const apiURL = `${API_ROOT}${API_URLS.SET_NOTIFICATION}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
} */

export async function topFive() {
    const apiURL = `${API_ROOT}${API_URLS.TOP_FIVE}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function streakInfo() {
    const apiURL = `${API_ROOT}${API_URLS.PURCHASE_STREAK}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function streakClaimReward() {
    const apiURL = `${API_ROOT}${API_URLS.STREAK_CLAIM_REWARD}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function claimReward(match) {
    const apiURL = `${API_ROOT}${API_URLS.CLAIM_REWARD}`;
    const requestObj = {
        match: match
    };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function quizInfo() {
    const apiURL = `${API_ROOT}${API_URLS.DAILY_QUIZ}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function startQuiz() {
    const apiURL = `${API_ROOT}${API_URLS.START_QUIZ}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function submitQuiz(responses) {
    const apiURL = `${API_ROOT}${API_URLS.SUBMIT_QUIZ}`;
    const requestObj = {
        responses: responses
    };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function predictGetChoice() {
    const apiURL = `${API_ROOT}${API_URLS.GET_CHOICE}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function predictPickChoice(id, choice) {
    const apiURL = `${API_ROOT}${API_URLS.PICK_CHOICE}`;
    const requestObj = {
        match: id,
        choice: choice
    }
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function topStackerCongratulationPoup() {
    const apiURL = `${API_ROOT}${API_URLS.TOPSTACKER_CONGRATULATION_POPUP}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function isNewUser() {
    const apiURL = `${API_ROOT}${API_URLS.IS_NEWUSER}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function getGiftCards() {
    const apiURL = `${API_ROOT}${API_URLS.GET_GIFTS_CARDS}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function claimGiftCard() {
    const apiURL = `${API_ROOT}${API_URLS.CLAIM_GIFT_CARD}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function prevWinners() {
    const apiURL = `${API_ROOT}${API_URLS.PREV_WINNERS}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.TOKEN, requestObj)
}

export async function postAnalytics(eventName) {
    const apiURL = `${API_ROOT}${API_URLS.POST_ANALYTICS}`;
    const requestObj = { eventName };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.TOKEN, requestObj)
}

export async function checkBal(data) {
    const apiURL = `${REWARD_STORE}${API_URLS.CHECK_BAL}`;
    const requestObj = { 
        hashKey: data.hashKey,
        dateTime: data.dateTime,
        hash: data.hash
    };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.ACCESSKEY, requestObj)
}

export async function orderConfirm(data) {
    const apiURL = `${REWARD_STORE}${API_URLS.ORDER_CONFIRM}`;
    const requestObj = {
        hashKey: data.hashKey,
        redeemType: data.redeemType,
        inrValue: data.inrValue,
        hash: data.hash
    };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.ACCESSKEY, requestObj)
}

export async function orderCreate(data) {
    const apiURL = `${REWARD_STORE}${API_URLS.ORDER_CREATE}`;
    const requestObj = {
        hash: data.hash,
        hashKey: data.hashKey,
        inrValue: data.inrValue,
        productName: data.productName,
        productPrice: data.productPrice,
        rewardType: data.rewardType,
        rewardValue: data.rewardValue,
        txnId: data.txnId
    };
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.ACCESSKEY, requestObj)
}

export async function orderProcess(data) {
    const apiURL = `${REWARD_STORE}${API_URLS.ORDER_PROCESS}`;
    const requestObj = data;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.ACCESSKEY, requestObj)
}

export async function checkOrderStatus(data) {
    const apiURL = `${REWARD_STORE}${API_URLS.ORDER_STATUS_CHECK}`;
    const requestObj = data;
    return commonApiCall(apiURL, 'POST', AUTH_TYPE.ACCESSKEY, requestObj)
}

export async function checkRewardValues() {
    const apiURL = `${REWARD_STORE}${API_URLS.CHECK_REWARD_VALUE}`;
    const requestObj = false;
    return commonApiCall(apiURL, 'GET', AUTH_TYPE.ACCESSKEY, requestObj)
}