import FAQ from "../components/FAQ";
import Header from "../components/Header";
import DailyQuiz from "../components/DailyQuiz";
import TermsAndCondition from "../components/TermsAndCondition";
// import Predict from "../components/predictTwo";
import Predict from "../components/IPL2024/index";
import TopStackers from "../components/TopStackers";
import MysteryRewards from "../components/MysteryRewards";
// import Predict from "../components/predict";
import PurchaseStreak from "./PurchaseStreak";
import FloatingButton from "../components/FloatingShareButton";
import JackpotRewards from "../components/jackpotRewards";
import { BsShareFill } from "react-icons/bs";
import * as APIHelper from '../Helper/apiFetch';
import * as nativeEvents from '../Helper/native';

const initiaData = require('../components/urlEncodeTest.json');

export default function Home() {

    const shareWithFriends = () => {
        APIHelper.postAnalytics("share_from_home");
        nativeEvents.shareWithFriends(initiaData.floatingShareMsg.msg);
    }

    return (
        <div style={{ backgroundColor: '#020C28' }}>
            {/*  <div className="floatingShareButtonContainer">
                 <button className="floatingShareButton" onClick={() => shareWithFriends() }>
                    <BsShareFill  size={24} />
                    Share
                </button> 
            </div> */}
            <Header />
            <Predict />
            {/* <DailyQuiz />  */}
            {/* <PurchaseStreak /> */}
            {/*<JackpotRewards />*/}
            {/* <TopStackers /> */}
            {/* <FAQ /> */}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <TermsAndCondition />
                <button className="footerShareButton" onClick={() => shareWithFriends()}>
                    <BsShareFill size={16} />  Share
                </button>
            </div>
            {/* <TermsAndCondition /> */}
        </div>
    )
}