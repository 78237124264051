import * as nativeEvents from '../Helper/native';
import { FiChevronLeft } from "react-icons/fi";
import Logo from "../images/logo.png";
import BodyImgTwo from '../images/BodyImgTwo.png';

export default function NonCardUser() {
    return (
            <div className="cardContainer">
            <button className='back-button' onClick={nativeEvents.goBack}>
                <FiChevronLeft className='back-button-icon' />
            </button>
                <div>
                    <img src={Logo} width="90px" height="auto" />
                </div>
                <div className="headerTitle">
                    REPUBLIC OF BITCOIN
                </div>
                <div className="headerSubtitle">
                    Jan 26th - Jan 29th
                </div>
                <div>
                    <img src={BodyImgTwo} width="300px" height="300px" />
                </div>
                <div className="bodyTitle" style={{ marginTop: "40px" }}>
                    Let's celebrate Republic Day together, with boosted rewards on your GoSats Card!
                </div>
                <div className="subPoints">
                    <ul>
                        <li>
                            Earn <span className="subColor">3% Bitcoin</span> on the Elite Card
                        </li>
                        <li>
                            Earn <span className="subColor">1% Bitcoin</span> on the Intro Card
                        </li>
                    </ul>
                </div>
                <div className="ctaContainer">
                    <button className="cardCta" onClick={nativeEvents.purchaseCard}>
                        Purchase now
                    </button>
                </div>
            </div>
    )
}