import { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import CardError from '../images/carderror1.png';

export default function WebError() {

    const timerRef = useRef();
    const [meta, setMeta] = useState("");
    const [timer, setTimer] = useState(false);

    const navigate = useNavigate();

    const goToHomeScreen = () => {
        navigate('/cc/:id')
    }

    return (
        <div className='webError'>
            <div style={{ marginTop: '' }}>
                <img src={CardError} width='261px' height='auto' />
                <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: '600', marginTop: '15px' }}>
                    Oops! Something went wrong. Please try again later
                </div>
                <button onClick={goToHomeScreen} className="ErrorRetry">
                    Retry
                </button>
            </div>
        </div>
    )
}