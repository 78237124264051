import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const TermsAndCondition = () => {

    const navigate = useNavigate();

    return (
        <div style={{ padding: "20px", color: "#A6A7A8" }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                <button className='back-button' onClick={() => { navigate('/home') }}>
                    <FiChevronLeft className='back-button-icon' />
                </button>
                <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 700, marginLeft: '20px' }}>FAQ</div>
            </div>
            <div>
                <ul class='mt-4'>
                    <li>
                        <p class='FAQ-Question'>What is the Bitcoin World Cup Mania Cricket Trivia Contest?</p>
                        <p class='paragraph'>The Bitcoin World Cup Mania Cricket Trivia Contest is a contest running on the
                            GoSats app during the ICC World Cup 2023. Users are presented with 3 multiple-choice questions
                            (MCQs) about cricket daily and have 20 seconds to answer each question.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>When does the contest end?</p>
                        <p class='paragraph'>The contest ends on November 19, 2023, coinciding with the conclusion of the
                            ICC World Cup 2023.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How many questions are presented daily?</p>
                        <p class='paragraph'>Users are presented with 3 MCQ questions daily.</p>
                    </li>
                    <li>
                        <p className='FAQ-Question'>Will I get streak reward for transactions where my preference is gold?</p>
                        <p className='paragraph'>No you will not be eligible for the same as the GoSats Bitcoin World Cup Mania is focused only on the Bitcoin rewards aspect whereas Gold rewards are enhanced on account of the festive season to 2% back if in Gold on the elite card usage.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How much time is provided to answer each question?</p>
                        <p class='paragraph'>You have 20 seconds to answer each question, and the timer is displayed.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>What is the minimum requirement to win a reward?</p>
                        <p class='paragraph'>To win a reward, you need to answer at least 2 out of the 3 questions correctly.
                        </p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>What can I win by participating in this contest?</p>
                        <p class='paragraph'>By getting at least 2 out of 3 answers correct, you have a chance to win up to
                            10,000 sats (satoshis).</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How are the winners determined?</p>
                        <p class='paragraph'>Winners are determined based on the number of correct answers provided within
                            the time limit. All users who answer 2 out of 3 questions will win the reward.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How will I receive my reward if I win?</p>
                        <p class='paragraph'>The Sats will be added to your GoSats Sats balance on winning the quiz.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Can I participate multiple times in a day?</p>
                        <p class='paragraph'>Each user can participate one time per day.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Why didn't I receive the 0.5% extra reward after making a card transaction?</p>
                        <p class='paragraph'>Only card transactions above Rs200 can be used to claim the offer. Also, card transactions made on Bill/Utility Payments, Digital Wallet Loads, Real Estate Payments, Government services, Telecommunications, Insurance, Quasi Cash, Fuel Payments & Education do not count.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How does the 0.5% extra rewards work?</p>
                        <p class='paragraph'>On winning the 0.5% extra rewards the user will be eligible to make one transaction
                            within the next 24 hours of winning the reward, where the user gets 0.5% extra bitcoin rewards.
                            <br />
                            Intro: 1% (0.5%+0.5%)
                            <br />
                            Elite: 2% (1.5%+0.5%)</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How does the Rs.250 discount work?</p>
                        <p class='paragraph'>When you win "The Rs.250 Off" reward, you will receive a coupon code. This coupon code can be
                            applied during the purchase of the GoSats Elite Card, allowing you to enjoy a Rs. 250 discount on the card's cost.
                        </p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>What is the Bitcoin World Cup Mania Weekly Purchase Streak offer?</p>
                        <p class='paragraph'>The Bitcoin World Cup Mania Weekly Purchase Streak offer is a promotion for
                            GoSats app and card users. It rewards users with 5000 Sats when they either purchase a voucher on
                            the app or use the GoSats Card to shop for a minimum of Rs.200 every day for a continuous streak of
                            7 days.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How can I participate in this offer?</p>
                        <p class='paragraph'>To participate, you need to either purchase a voucher on the GoSats app or use
                            the GoSats Card to shop for a minimum of Rs.200 every day for a streak of 7 days.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Can I avail of this offer multiple times?</p>
                        <p class='paragraph'>Yes, you can avail of this offer multiple times. You can start a new streak
                            after completing or breaking the previous one.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>What are the criteria to qualify for the 5000 Sats reward?</p>
                        <p class='paragraph'>To qualify for the 5000 Sats reward, you need to maintain a seven-day streak of
                            either purchasing a voucher every day or using the GoSats Card to shop for more than Rs.200 each
                            day.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>How can I check the status of my streak?</p>
                        <p class='paragraph'>You can check the status of your streak on the GoSats app.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Is there a limit to the number of times I can enter the streak during the
                            offer period?</p>
                        <p class='paragraph'>The offer is valid till November 16, 2023. You can enter the streak up to
                            November 10, 2023, within this period.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Do I need to do anything special to enroll in the streak, or is it
                            automatic?</p>
                        <p class='paragraph'>The streak tracking is automatic, but it's essential to ensure that you meet
                            the criteria (daily voucher purchase or using the card for shopping) to maintain your streak.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>When will I receive the 5000 Sats reward?</p>
                        <p class='paragraph'>5000 Sats will be added to the users GoSats account on completing the streak
                            and streak will be reset.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>Can I use any voucher from the GoSats app to participate in this offer?</p>
                        <p class='paragraph'>You can use purchase any voucher listed on the GoSats app to qualify for the
                            streak.</p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>I made a purchase, but this hasn’t been included in the Weekly Purchase
                            Streak or towards the leaderboard for Highest Weekly Stacker. Why?</p>
                        <p class='paragraph'>Please note that only card transactions above Rs200 and brand voucher purchases count towards both the Weekly Purchase Streak and the Highest Stacker of the Week.
                            <br />
                            Also, the following card transactions are not counted Bill/Utility Payments, Digital Wallet Loads, Real Estate Payments, Government services, Telecommunications, Insurance, Quasi Cash, Fuel Payments & Education.
                        </p>
                    </li>
                    <li>
                        <p class='FAQ-Question'>What are the jackpot rewards for Highest Stacker of the Week?</p>
                        <p class='paragraph'>Every week, the highest stacker of each week is rewarded with an exciting prize
                            that is revealed in the app at the start of each week.</p>
                    </li>
                </ul>
            </div>
        </div>


    )
}

export default TermsAndCondition;