import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import "./index.css";
import * as APIHelper from '../Helper/apiFetch';

const RewardStore = () => {
    const [cart, setCart] = useState([]);
    const [products, setProducts] = useState([]);
    const [balance, setBalance] = useState(null);
    const [rewardValue, setRewardValue] = useState(null);
    const [error, setError] = useState(null);
    const [OrderConfirmSucess, setOrderConfirmSucess] = useState('');
    const [orderPlaceSuccess, setOrderPlaceSuccess] = useState('');
    const [inrValues, setInrValues] = useState('');
    const [productNames, setProductNames] = useState('');
    const [productPrice, setProductPrice] = useState();
    const [orderPlacedSuccess, setOrderPlacedSuccess] = useState('');
    const [hashKey, setHashKey] = useState('');
    const [redeemType, setRedeemType] = useState('sats');

    const createSignature = (data) => {
        const accessKey = 'GOSATSACCESS';
        const sortedKeys = Object.keys(data).sort();
        const sortedValues = sortedKeys.map(key => String(data[key]));
        const hashstring = accessKey + '|' + sortedValues.join('|');
        return CryptoJS.SHA512(hashstring).toString(CryptoJS.enc.Hex);
    };
    const fetchInitialData = async () => {
        try {
            const dateTime = Date.now();
            const signature = createSignature({ hashKey, dateTime });

            const balanceResp = await APIHelper.checkBal({ hashKey, dateTime, hash: signature });
            if (!balanceResp.error) setBalance(balanceResp.data.balance);

            const rewardValueResp = await APIHelper.checkRewardValues();
            if (!rewardValueResp.error) setRewardValue(rewardValueResp.data);

            setProducts([
                { id: 1, name: 'Brand A - Product 1', price: 100 },
                { id: 2, name: 'Brand A - Product 2', price: 200 },
                { id: 3, name: 'Brand B - Product 1', price: 150 },
                { id: 4, name: 'Brand B - Product 2', price: 250 },
            ]);
        } catch (error) {
            console.error("Failed to fetch initial data", error);
        }
    };

    const handleHashKeyChange = (event) => {
        setHashKey(event.target.value);
    };

    const handleRedeemTypeChange = (event) => {
        setRedeemType(event.target.value);
    };

    const addToCart = async (item) => {
        const data = {
            hashKey: hashKey,
            redeemType: redeemType,
            inrValue: item.price
        };

        try {
            const hash = createSignature(data);
            const requestData = { ...data, hash: hash };
            const resp = await APIHelper.orderConfirm(requestData);

            if (!resp.error) {
                setCart([...cart, item]);
                setError(null);
            } else {
                setError(resp.message);
            }
        } catch (e) {
            console.error("Error confirming order", e);
            setError("Failed to add to cart");
        }
    };

    const removeFromCart = (index) => {
        const newCart = [...cart];
        newCart.splice(index, 1);
        setCart(newCart);
    };

    const confirmOrder = async () => {
        const data = {
            hashKey: hashKey,
            redeemType: redeemType,
            inrValue: cart.reduce((acc, item) => acc + item.price, 0)
        };

        try {
            const hash = createSignature(data);
            const requestData = { ...data, hash: hash };
            const resp = await APIHelper.orderConfirm(requestData);
            if (!resp.error) {
                setOrderConfirmSucess(resp.data);

                // Update the inrValues state
                const newInrValues = cart.map(item => item.price);
                setInrValues(newInrValues);
                const newProductNames = cart.map(item => item.name);
                const newProductPrice = cart.map(item => item.price);
                setProductNames(newProductNames);
                setProductPrice(newProductPrice);
                setCart([]);
            } else {
                alert('Failed to confirm order: ' + resp.message);
            }
        } catch (e) {
            console.error("Error confirming order", e);
        }
    };

    const placeOrder = async () => {
        const data = {
            hashKey: hashKey,
            // orderId,
            inrValue: inrValues[0],
            rewardType: redeemType,
            rewardValue: '3245345',
            productName: productNames[0],
            productPrice: productPrice[0],
            txnId: 'JHADGJFSDFJ234234',
        };

        try {
            const hash = createSignature(data);
            const requestData = { ...data, hash: hash };
            const createResp = await APIHelper.orderCreate(requestData);
            if (!createResp.error) {
                setOrderPlaceSuccess(createResp.data);
                const { productName, productPrice, ...processData } = data;
                processData.orderId = createResp.data.orderId;
                processData.isPaymentProcessed = true;

                const processHash = createSignature(processData);
                const processRequestData = { ...processData, hash: processHash };
                const processResp = await APIHelper.orderProcess(processRequestData);
                if (!processResp.error) {
                    setOrderPlacedSuccess(processResp.data);
                    alert(processResp.message)
                } else {
                    setError('Failed to process order: ' + processResp.message);
                }
            } else {
                setError('Failed to place order: ' + createResp.message);
            }
        } catch (e) {
            console.error("Error placing order", e);
            setError("Failed to place order");
        }
    };

    return (
        <div style={{ color: '#FFFFFF' }}>
            <h1>Reward Store</h1>
            <div>
                <label>Hash Key: </label>
                <input type="text" value={hashKey} onChange={handleHashKeyChange} />
                <button onClick={fetchInitialData}>Fetch User Balance</button>
            </div>
            <div>
                <label>Redeem Type: </label>
                <select value={redeemType} onChange={handleRedeemTypeChange}>
                    <option value="sats">Sats</option>
                    <option value="gold">Gold</option>
                </select>
            </div>
            {balance && (
                <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                    <h2>User Balance</h2>
                    <p>Gold Spendable: {balance.gold.spendable}</p>
                    <p>Gold INR Value: {balance.gold.inrValue}</p>
                    <p>Sats Spendable: {balance.sats.spendable}</p>
                    <p>Sats INR Value: {balance.sats.inrValue}</p>
                </div>
            )}
            {rewardValue && (
                <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                    <h2>Reward Prices</h2>
                    <p>Sats Price: {rewardValue.satsPrice}</p>
                    <p>Gold Price: {rewardValue.goldPrice}</p>
                </div>
            )}
            {
                OrderConfirmSucess && (
                    <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                        <h2>Order Confirmed</h2>
                        <p>Reward Type: {OrderConfirmSucess.redeemType}</p>
                        <p>Reward INR Price : {OrderConfirmSucess.inrValue}</p>
                        <p>Reward Value: {OrderConfirmSucess.rewardValue}</p>
                        <button onClick={placeOrder}>Place Order</button>
                    </div>
                )
            }
            {orderPlaceSuccess && (
                <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                    <h2>Order Placed</h2>
                    <p>Order ID: {orderPlaceSuccess.orderId}</p>
                </div>
            )}
            <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                <ProductList products={products} addToCart={addToCart} />
            </div>
            <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                <ShoppingCart cart={cart} removeFromCart={removeFromCart} confirmOrder={confirmOrder} />
            </div>
            {error && (
                <div style={{ border: "1px solid #FFFFFF", marginTop: '10px' }}>
                    <p style={{ color: 'red' }}>{error}</p>
                </div>
            )}
        </div>
    );
};

const ProductList = ({ products, addToCart }) => (
    <div>
        <h2>Products</h2>
        {products.map(product => (
            <div key={product.id}>
                <h3>{product.name}</h3>
                <p>Price: {product.price}</p>
                <button onClick={() => addToCart(product)}>Add to Cart</button>
            </div>
        ))}
    </div>
);

const ShoppingCart = ({ cart, removeFromCart, confirmOrder }) => (
    <div>
        <h2>Shopping Cart</h2>
        {cart.length === 0 && <p>Cart is empty</p>}
        {cart.map((item, index) => (
            <div key={index}>
                <h3>{item.name}</h3>
                <p>Price: {item.price}</p>
                <button onClick={() => removeFromCart(index)}>Remove</button>
            </div>
        ))}
        {cart.length > 0 && (
            <div>
                <h3>Total: {cart.reduce((acc, item) => acc + item.price, 0)}</h3>
                <button onClick={confirmOrder}>Confirm Order</button>
            </div>
        )}
    </div>
);

export default RewardStore;