
import { useState } from 'react';
import LandingImg from '../images/LandingImg.png';
import Divider from '../components/UI/Divider';
import Ind from '../images/Ellipse.png';
import check from '../images/crctAns.png';
import * as APIHelper from '../Helper/apiFetch';
import VerifyOtp from './verifyOtp';
import { useNavigate, useNavigation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';

export default function InputPhoneNum() {
    const [phoneNumber, setPhoneNumber] = useState(" ")
    const [btnDisabled, setBtnDisabled] = useState(true);

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (state && state.phoneNumber && state.hash) {
            setPhoneNumber(state.phoneNumber);
            setBtnDisabled(true);
        }
    }, [])

    const handelChange = e => {
        if (e.target.value.length == 10) {
            setBtnDisabled(false);
        } else {
            setBtnDisabled(true)
        }
        var number = e.target.value
        setPhoneNumber(number)
    }

    const handleSubmit = async () => {
        setBtnDisabled(true);
        try {
            await APIHelper.signIn(phoneNumber).then(resData => {
                if (resData.error) {
                    navigate('/cc/error');
                }
                if (resData.error) {
                    navigate('/cc/error');
                }
                navigate('/cc/verifyOtp', { state: { phoneNumber, hash: state.hash } });
            })
        } catch (e) {
            console.log("Error", e)
        }

    }

    return (
        <div className='phoneNumContainer'>
            <div>
                <div className='landingScreenImgContainer'>
                    <img src={LandingImg} width={'285px'} height={"auto"} className="landingScreenImg" />
                </div>
                <div style={{ marginTop: '25px', marginBottom: '14px' }}>
                    <Divider />
                </div>
                <div style={{ color: '#FFFFFF', fontSize: '16px', fontWeight: 400 }}>
                    Enter Phone number
                </div>
                <div className='inputPhoneNumContainer'>
                    <div className='phoneNumSubContainer'>
                        <img src={Ind} width='20px' height='20px' style={{ marginRight: '8px' }} />
                        (+91)
                        <div className='phoneNumDiveider'></div>
                        <div style={{ display: 'flex' }}>

                            <input className='inputNum'
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                                type='number'
                                value={phoneNumber}
                                placeholder="Eg: 9898989898"
                                onChange={handelChange}
                                autoFocus={true}
                            />
                            <div>
                                {
                                    btnDisabled ?
                                        null
                                        :
                                        <img src={check} width='15px' height='14px' />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button disabled={btnDisabled} onClick={handleSubmit} style={{ background: btnDisabled ? "#343739" : '#D1964A', color: btnDisabled ? "#646667" : '#FFFFFF' }} className='inputContinueBtn'>
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

