import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import winSatsIcon from '../images/congratsIcon1.png';
import Modal from "react-modal";
import { BsShareFill } from "react-icons/bs";
import * as nativeEvents from '../Helper/native';
import * as APIHelper from '../Helper/apiFetch';
import Lottie from "lottie-react";
import CongLottie from '../../src/lottie.json';
Modal.setAppElement("#root");

const initiaData = require('./urlEncodeTest.json');

const Congratulation = ({ title, discountCode, cardType, quiz }) => {
    const [modal, setModal] = useState(true);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setModal(false);
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [])

    const navigateHome = () => {
        setModal(false);
        navigate('/home');
        window.location.reload();
    }

    const shareWithFriends = () => {
        // APIHelper.postAnalytics("share_from_claim");
        nativeEvents.shareWithFriends(initiaData.popUpShareMessage.msg);
    }

    /*  const getCard = (code) => {
         nativeEvents.purchaseCard(code);
     } */

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-model')
            document.body.style.overflow = 'hidden'
        } else {
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [modal])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: CongLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const getCard = () => {
        const toastMessage = document.getElementById("toastMessage");
        navigator.clipboard.writeText(discountCode);
        showToast(toastMessage);
    }

    function showToast(toastMessage) {
        toastMessage.classList.add("show");
        setTimeout(() => {
            nativeEvents.purchaseCard(discountCode);
            toastMessage.classList.remove("show");
        }, 2000);
    }

    return (
        <div >
            {modal && (
                <Modal
                    isOpen={isOpen}
                    closeTimeoutMS={500}
                    className="mymodal"
                    contentLabel="My dialog"
                    overlayClassName="myoverlay"
                >
                    <div className=' cong-container '>
                        <div className='cong' >
                            <div className='cong-Subcontainer'>
                                <div className='cong-iplStreak-container'>
                                    <img src={winSatsIcon} alt=' ' style={{width: '95px', height: '94px', marginTop: '20px'}}/>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <h1 className='cong-title' style={{ color: title?.shareButton === undefined ? '#FB604A' : '#000000', paddingTop: quiz === false ? '20px' : '50px' }}>{title?.title}</h1>
                                    <p className='cong-subTitle' dangerouslySetInnerHTML={{ __html: title?.msg1 }} />
                                    <div>
                                        <button className='cong-button' onClick={() => shareWithFriends()}>{title?.shareButton}</button>
                                    </div>
                                    <div>
                                        <button className='cong-HomeButn' onClick={navigateHome}>{title?.doneButton}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className=' cong-container '>
                        <div className='cong' >
                            <div className=' cong-Subcontainer'>
                                {
                                    quiz === false ?
                                        null
                                        :
                                        <div className='cong-iplStreak-container'>
                                            <Lottie
                                                options={defaultOptions}
                                                height={250}
                                                width={300}
                                                style={{ marginTop: '-93px' }}
                                            />
                                        </div>
                                }

                                <div style={{ position: 'relative' }}>
                                    <h1 className='cong-title' style={{ color: title?.shareButton === undefined ? '#FB604A' : '#000000', paddingTop: quiz === false ? '20px' : '130px' }}>{title?.title}</h1>
                                    <p className='cong-subTitle' dangerouslySetInnerHTML={{ __html: title?.msg1 }} />
                                    {
                                        quiz === false ?
                                            <div>
                                                <button className='cong-HomeButn' onClick={navigateHome}>{title?.doneButton}</button>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div>
                                    {
                                        quiz === false ?
                                            null
                                            :
                                            <div>
                                                <div className='congDivider'>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="congPopUpTitle">
                                                        Share with your friends and earn more!
                                                    </div>
                                                    <div>
                                                        {
                                                            cardType === "undefined" ?
                                                                <div>
                                                                    <button className='cong-button' onClick={() => shareWithFriends()}>{title?.shareButton}<BsShareFill size={16} /></button>
                                                                </div>
                                                                :
                                                                <div>
                                                                    {cardType === "undefined" ? <button className='cong-button' onClick={() => getCard()}>{title?.getCard}</button> : <button className='cong-button' onClick={() => shareWithFriends()}>{title?.shareButton}<BsShareFill size={16} /></button>}
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            {
                                quiz === false ?
                                    null
                                    :
                                    <div>
                                        {
                                            discountCode != undefined ?
                                                <div>
                                                    <button className='shareCong-button' onClick={() => getCard(discountCode)}>{title?.getCard}</button>
                                                </div>
                                                :
                                                <div>
                                                    <button className='cong-HomeButn mb-4' onClick={navigateHome}>{title?.doneButton}</button>
                                                </div>
                                        }

                                    </div>
                            }
                        </div>
                        <div class="toast" id="toastMessage" style={{width:'maxContent'}}>Coupon Code copied to clipboard</div>
                    </div> */}
                </Modal>
            )}
        </div>
    );
}

export default Congratulation;


