import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import winSatsIcon from '../images/winSatsIcon.png';
import Modal from "react-modal";
import * as nativeEvents from '../Helper/native';
import timer from '../images/timer.png';
Modal.setAppElement("#root");

const   QuizBackPopup = ({ title, message }) => {
    const [modal, setModal] = useState(true);
    const [count, setCount] = useState(5);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
   
    useEffect(() => {
        const timer = setInterval(() => {
          setCount(count => count - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
          };
        }, [count]);

        useEffect(() => {
            if (count === 0) {
              setModal(false);
            }
          }, [count]);

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setModal(false);
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [])

    const navigateHome = () => {
        setModal(false);
        navigate('/home');
    }

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-model')
            document.body.style.overflow = 'hidden'
        } else {
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [modal])


    return (
        <div >
            {modal && (
                <Modal
                    isOpen={isOpen}
                    closeTimeoutMS={500}
                    className="mymodal"
                    contentLabel="My dialog"
                    overlayClassName="myoverlay"
                >
                    <div className='cong-container' style={{margin: '40% 20px 0px 20px' }}>
                        <div className=' cong-Subcontainer'>
                            <div className='quizTimerContainer'> 
                            <div className='quizTimer' style={{marginTop: '-25px'}}>
                                <img src={timer} className='timer-Icon' alt=' ' />
                                <div style={{ color: '#FFFFFF' }}>{count}</div>
                            </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <h1 className='cong-title' style={{ color: '#FB604A', paddingTop: '10px', fontSize: '24px' }}>Do You Really Want To Go Back?</h1>
                                <p className='cong-subTitle mb-4'>You may lose your quiz progress once you go back</p>
                            </div>
                            <button className='cong-button' style={{padding: '11px 68px', borderRadius: '0px', backgroundColor: '#FF00A5'}} onClick={() => {setModal(false)}}>NO, LET ME CONTINUE</button>
                            <button className='cong-HomeButn mb-4' style={{padding: '11px 68px', border: '1px solid var(--error-bright, #FB604A)', backgroundColor: 'transparent', color: '#FB604A'}} onClick={() => {navigateHome()}}>YES, TAKE ME BACK</button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default QuizBackPopup;


