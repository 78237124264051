import { useState, useEffect } from "react";
import PopupButton from "../../components/UI/PopupButton";
import * as nativeEvents from "../../Helper/native";
import Card from '../../components/UI/Card';
import ConfettiComp from "../UI/Confetti";
import PopupCongratsTitle from "../../components/UI/PopupCongratsTitle";
import BackButton from "../../components/UI/BackButton";
import { FiChevronLeft } from "react-icons/fi";
import Modal from 'react-modal';
import * as APIHelper from '../../Helper/apiFetch';
Modal.setAppElement("#root");

export default function NoCard(props) {

    var { reward } = props

    const [modal, setModal] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setModal(false);
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [])

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-model')
            document.body.style.overflow = 'hidden'
        } else {
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [modal])

    const close = () => {
        setIsOpen(false);
    }

    return (
        <div>
            {
                modal && (
                    <Modal
                        isOpen={isOpen}
                        closeTimeoutMS={500}
                        className="mymodal"
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                    >
                        <ConfettiComp />
                        <div>
                            <div className="popupBackButtonContainer">
                                <button className='back-button' onClick={close}>
                                    <FiChevronLeft className='back-button-icon' />
                                </button>
                            </div>
                            <div style={{ color: '#FFFFFF', height: '100vh', paddingTop: '10%' }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Card title={reward} />
                                    </div>
                                    <PopupCongratsTitle title={reward} />
                                    <div className="popUpButtonContainer">
                                        <button onClick={() => {
                                            APIHelper.postAnalytics("get_the_card");
                                            nativeEvents.purchaseCard();
                                        }} className="popUpButton">
                                            GET THE CARD
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    );
}

