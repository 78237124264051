import { useNavigate } from 'react-router-dom';
import ErrorImg from '../images/OlduserError.png';

export default function OlduserError() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/cc/:id');
    }

    return (
        <div className='olduserErrorContainer'>
            <img src={ErrorImg} width='261px' height='auto' />
            <div style={{ color: '#FB604A', fontSize: '24px', fontWeight: '700', marginTop: '20px', marginBottom: '15px' }}>
                Uh-oh
            </div>
            <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: '600', marginBottom: '120px' }}>
                This is a limited edition card only for new users. You can share this card kit with a friend or family member instead.
            </div>
            <button onClick={goBack} className='inputContinueBtn' style={{ color: '#FFFFFF', background: '#D1964A' }}>
                Go Back
            </button>
        </div>
    )
}