import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from 'react-spinners';
import { FaChevronLeft } from "react-icons/fa";
import { RiPencilLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import Divider from "../components/UI/Divider";
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import * as APIHelper from '../Helper/apiFetch';

export default function VerifyOtp(props) {
    const [otp, setOtp] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [resendBtnDisabled, setResendBtnDisabled] = useState(true);
    const [timer, setTimer] = useState(30);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [hideResendBtn, setHideResendBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        timer > 0 && setTimeout(timeOutCallback, 1000);
    }, [timer, timeOutCallback]);

    const handleChange = (value) => {
        setOtp(value);
        const otpRegex = new RegExp('^\\d{3}$');
        const validity = otpRegex.test(otp);

        if (validity) {
            setBtnDisabled(false);
        }
    }

    const editNumber = () => {
        navigate('/cc/inputPhoneNum', { state: state });
    }

    const handleVerifyOtp = async () => {
        setBtnDisabled(true);
        setShowMessage(false);
        setLoader(true);
        try {
            await APIHelper.otpVerfy(state.phoneNumber, otp, state.hash).then(resData => {
                setLoader(false);
                if (!resData.error && resData.success) {
                    navigate('/cc/congrats')
                } else if (resData.error && resData.errorCode === "E1002") {
                    setMessage(resData?.message)//Set error message
                } else if (resData.error && resData.errorCode === "E1003") {
                    navigate('/cc/olduserError')
                } else if (resData.error && resData.errorCode === "E1004") {
                    navigate('/cc/kitassignedalready')
                } else {
                    navigate('/cc/error')
                }

                if (resData.message) {
                    setShowMessage(true);
                }
            })
        }
        catch (e) {
            console.log("Error", e)
        }
    }

    const resendOtp = async () => {
        setShowMessage(false);
        setResendBtnDisabled(false);
        setHideResendBtn(true);
        try {
            await APIHelper.signIn(state.phoneNumber).then(resData => {
                if (resData.error) {
                    navigate('/cc/error');
                }
            })
        } catch (e) {
            console.log("Error", e)
        }
    }

    return (
        <div className="verifyOtpContainer">
            <div className='verifyBack  pt-4'>
                <button onClick={editNumber} className='back-button'>
                    <FaChevronLeft className='back-button-icon' />
                </button>
                <div style={{ color: "#FFFFFF", marginLeft: '16px' }}>Verify Number</div>
            </div>
            <div style={{ color: '#A6A7A8', marginTop: '20px' }}>
                We have sent 4 digit code to
            </div>
            <div style={{ color: '#D1964A', display: 'flex', alignItems: 'center' }}>
                (+91) {state?.phoneNumber}<button onClick={editNumber} className="editBtn"><RiPencilLine /></button>
            </div>
            <div style={{ marginTop: '25px', color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>
                Enter OTP for verification
            </div>
            <div className="otp-input">
                <OtpInput
                    onChange={handleChange}
                    isInputNum={true}
                    numInputs={4}
                    value={`${otp}`}
                    inputStyle="inputOtp"
                    containerStyle="inputContainer"
                    renderInput={(inputProps, index) => {
                        return <input {...inputProps} />;
                    }}
                />
            </div>
            <div style={{ marginTop: '20px', marginBottom: '15px' }}>
                <Divider />
            </div>
            <div>
                {
                    hideResendBtn ?
                        null
                        :
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ color: '#A6A7A8', fontSize: '14px', fontWeight: 600 }}>Didn’t receive the OTP ?</div>
                            <button className="resendContainer" onClick={resendOtp} disabled={(timer !== 0 || !resendBtnDisabled) ? true : false} style={(timer === 0 || !resendBtnDisabled) ? { background: '#D1964A', color: '#FFFFFF' } : null}>Resend OTP in 00:{timer}</button>
                        </div>
                }
            </div>
            <div style={{ color: '#FB604A', marginTop: '10px' }}>{showMessage ? message : null}</div>
            <div>
                <button className="inputContinueBtn" disabled={btnDisabled} onClick={handleVerifyOtp} style={{ background: btnDisabled ? "#343739" : '#D1964A', color: btnDisabled ? "#646667" : '#FFFFFF' }}>
                    {
                        loader ? <ClipLoader size={24} color={'D1964A'}/> 
                        :
                         "Continue"
                    }
                </button>
            </div>
        </div>
    )
}


