export default function PopupCongratsTitle(props) {
    var { title } = props;
    return (
        <div className="PopupCongratsTitleSection">
            <div>
                <div style={{ color: '#FFFFFF', fontSize: '24px', fontWeight: 700 }}>
                    Congratulations
                </div>
                <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 600 }}>
                    {title?.msg}
                </div>
            </div>
        </div>
    )
}