import { useRef, useState, useEffect } from "react";
import { FiChevronLeft } from "react-icons/fi";
import PopupButton from "../../components/UI/PopupButton";
// import useWindowSize from 'react-use/lib/useWindowSize';
// import Confetti from 'react-confetti';
import ConfettiComp from "../UI/Confetti";
import * as nativeEvents from "../../Helper/native";
import Card from '../../components/UI/Card';
import PopupCongratsTitle from "../../components/UI/PopupCongratsTitle";
import BackButton from "../../components/UI/BackButton";
import Modal from 'react-modal';
import * as APIHelper from '../../Helper/apiFetch';
Modal.setAppElement("#root");

export default function TwoxRewards(props) {

    var { reward } = props;
    const timerRef = useRef();
    const [modal, setModal] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [stopConfetti, SetstopConfetti] = useState(true);
    const [timer, setTimer] = useState(false);


    useEffect(() => {
        setIsOpen(true);
        return () => {
            setModal(false);
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [])

    /* useEffect(() => {
        if (timer) return
        timerRef.current = setInterval(() => {
            SetstopConfetti(false)
        }, 4 * 1000);
        setTimer(false);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [modal]) */

    useEffect(() => {
        if (modal) {
            document.body.classList.add('active-model')
            document.body.style.overflow = 'hidden'
        } else {
            document.body.classList.remove('active-model')
            document.body.style.overflow = 'auto'
        }
    }, [modal])

    const close = () => {
        setIsOpen(false);
    }

    return (
        <div>
            {
                modal && (
                    <Modal
                        isOpen={isOpen}
                        closeTimeoutMS={500}
                        className="mymodal"
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                    >
                        <ConfettiComp />
                        <div>

                            <div className="popupBackButtonContainer">
                                <button className='back-button' onClick={close}>
                                    <FiChevronLeft className='back-button-icon' />
                                </button>
                            </div>
                            <div style={{ color: '#FFFFFF', height: '100vh', paddingTop: '10%' }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Card title={reward} />
                                    </div>
                                    <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                                        <div style={{ color: "#FFFFFF", fontSize: '15px', fontWeight: 700 }}>IT'S A GREAT DAY TO GET SOME<br /> SHOPPING DONE! EARN:</div>
                                        <div style={{ color: '#A6A7A8', fontSize: '14px', fontWeight: 400, marginTop: '20px' }}>
                                            <ul>
                                                <li>
                                                    Flat 3% (Elite card users) & 1% (Intro card users) on Card Transactions
                                                </li>
                                                <li>
                                                    2x Rewards on Voucher purchases
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="popUpButtonContainer">
                                        <button onClick={() => {
                                            APIHelper.postAnalytics("shop_now");
                                            nativeEvents.shopNow();
                                        }} className="popUpButton">
                                            SHOP NOW
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </div>
    );
}

