import React from 'react';
import './shimmer.css';
import { ShimmerText, ShimmerPostItem  } from "react-shimmer-effects";
import * as nativeEvents from '../Helper/native';
import { FiChevronLeft } from 'react-icons/fi';

const shimmer = [
    {
        rows: 1,
        width: '250',
        height: 20,
        width2: '182',
        height2: 4,
        height3: 130,
        width3: '100%',
        rows3: 1,
        width4: '325',
        width5: '40',
        width6: '200',
        width7: '102',
        width8: '80',
        width9: '40',
        height4: '70',
        width10: '135',
        height5: '48',
        width11: '314',
        height6: '80',
        width12: '80',
        width13: '164'
    }
]

// export const NotificationContext = React.createContext({ loader: false, notify: false, enableNotification: () => { }});
// TODO: needs changes
const renderShimmer = () => {
    return (shimmer?.map((data, index) => {
        return (
            <div key={index} style={{ background: "#040915", height: '100%', paddingBottom: '20px' }}>
            <div className="shimmer-effect">
            </div>
            </div>
        )
    }
    ))
}

export default renderShimmer;