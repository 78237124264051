import { useRef, useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { TbRefresh } from "react-icons/tb";
import * as nativeEvents from '../../Helper/native';
import * as APIHelper from '../../Helper/apiFetch';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '../../images/T20_Error.png';

export default function Error() {

    const timerRef = useRef();
    const [meta, setMeta] = useState("");
    const [timer, setTimer] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (timer) return;
        timerRef.current = setInterval(() => {
            APIHelper.authCheck().then(resp => {
                if (resp.error) {
                    // console.log(resp);
                    setMeta(resp.errorCode + " JWT:" + sessionStorage.getItem('AccessToken')?.substring(0, 10));
                } else {
                    navigate("/home");
                }
            })
        }, 3 * 1000);
        setTimer(true);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [])

    return (
        <div className='serverErrorBG container'>
            <div className='section-one pt-4'>
                <button className='back-button' onClick={nativeEvents.goBack}>
                    <FiChevronLeft className='back-button-icon' />
                </button>
            </div>
            <div className='serverError-container'>
                <div>
                    <img className='error-Img' src={ErrorIcon} />
                </div>
                <div className='serverErrorTitle'>
                    <div style={{ color: '#FFFFFF', fontSize: "18px"}}>Oops!</div>
                    <div className="col-10" style={{ color: '#A6A7A8', marginTop: '15px', padding: '0px 45px 0px 45px', fontSize: "16px"}}>Something went wrong, Please try again later.</div>
                    {/* <div>{meta}</div> */}
                </div>
            </div>
        </div>

    )
}