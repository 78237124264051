import popupIcon from '../../images/popupIcon.png'
import iconBg from '../../images/iconBg.png';
export default function Card(props) {
    var { title } = props;


    return (
        <div className="CardSection">
            <div>
                <div className="CardSubSectionOne" style={{ backgroundColor: title?.backgroundColor }}>
                </div>
                <div className="CardSubSectionTwo">
                    <div>
                        <div className='logoContainer' style={{ backgroundColor: title?.iconbgColor }}>
                            <img className="logobgImg" src={iconBg} />
                            <img src={title?.icon} style={{ width: '55px', height: 'auto' }} />
                        </div>
                        <div style={{ fontWeight: 700, fontSize: '18px', color: '#212426', marginTop: '25px' }}>
                            You Won
                        </div>
                        <div style={{ fontWeight: 700, fontSize: '24px', color: '#D1964A', marginTop: '5px', width:'340px', padding:'0px 40px' }}>
                            {title?.cardTitle}
                        </div>
                        <div style={{ fontWeight: 400, fontSize: '16px', color: '#343739', margin: '0px 20px 38px 20px' }}>
                            {title?.cardSubtitle}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}