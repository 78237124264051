import CongratsImg from '../images/congrats.png';
import Store from '../images/store.png';
export default function CongratsScreen() {
    return (
        <div className="congratsTitleContainer">
            <div style={{ marginBottom: '65px' }}>
                <img src={CongratsImg} width={'217px'} height={'auto'} />
            </div>
            <div style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 400, marginBottom: '100px' }}>
                <div style={{ margin: '10px' }}>Looks like this card has already been assigned to someone else.</div>
                <div style={{ marginTop: '20px' }}>Registration must be completed by the same number entered the first time.
                </div>
            </div>
            <form action="https://gosats.onelink.me/O1LB/628b4c27">
                <button className='inputContinueBtn' style={{ background: '#D1964A', color: '#FFFFFF', fontSize: '16px', fontWeight: 600 }}>
                    Download the App
                </button>
            </form>
        </div>
    )
}