import React, { useState } from 'react';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const TermsAndCondition = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <div className='valentineTandCContainer'>
                <div className="accordion-title d-flex justify-content-between align-items-center" onClick={() => setIsActive(!isActive)}>
                    <div className="col-11 title">
                        <div className='faq-container d-flex'>
                            <span className='termsTitle'>Terms & Conditions</span>
                        </div>
                    </div>
                    <div className="col-1 d-flex justify-content-end"><span className="row toggleBtn">{isActive ? <FaAngleUp /> : <FaAngleDown />}</span></div>
                </div>
                {isActive && <div className="accordion-content subText">
                    <div>
                        <ul className='termAndConditionPoints mt-4'>
                            <li>The GoSats Valentine's Week Offer: Card Purchase and Upgrade ("the offer") is applicable only to users who purchase the card or upgrade the card during the offer period.</li>
                            <li>The GoSats Elite Card will be available at Rs.499 during the offer period and will have life time validity. </li>
                            <li>The GoSats Intro Card will be available at Rs.99 during the offer period and will have life time validity. </li>
                            <li>The GoSats Intro Card users will be able to upgrade their Intro card to an Elite Card by paying Rs.399 during the offer period and will have life time validity. </li>
                            <li>The offer is applicable only from 10th February 2024 to 14th February 2024.</li>
                            <li>The discount offer can be availed only once per user.</li>
                            <li>The offer cannot be combined or clubbed with any other discount codes or promotions.</li>
                            <li>Disputes arising in connection with the offer shall be subject to the jurisdiction of the courts in Bangalore.</li>
                            <li>GoSats reserves the right to modify or cancel the offer at any time without prior notice to users.</li>
                            <li>GoSats reserves the right to disqualify any user from availing the offer if there is any suspicion of fraudulent activities or violation of the terms and conditions.</li>
                            <li>The offer is subject to the applicable laws.</li>
                            <li>Users are required to comply with all KYC and verification requirements as per GoSats's policies and Indian regulations.</li>
                            <li>By participating in the offer, users agree to be bound by these terms and conditions and any decision made by GoSats regarding the offer.</li>
                            <li>Please note that these terms and conditions are subject to change, and it is recommended to refer to the official GoSats website or communication channels for the most up-to-date information on the offer.</li>
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TermsAndCondition;