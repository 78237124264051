import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom';
import '../Wazirx.css';

/* const BrandImgs = [
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690200708568",
        "bgColor": "#B3372A",
        "monthsTitleBg": "#5F221C",
        "monthsTitle": "₹100 Off",
        "monthsTitleColor": "#A6A7A8",
        "worthTitle": "Meal at just ₹329",
        "worthTitleColor": "#FFFFFF"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009533",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#FFD2D2",
        "monthsTitle": "3 Months",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹999",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009613",
        "bgColor": "#9A1AC6",
        "monthsTitleBg": "#471B56",
        "monthsTitle": "6 Months",
        "monthsTitleColor": "#A6A7A8",
        "worthTitle": "Worth ₹699",
        "worthTitleColor": "#FFFFFF"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009794",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#FFD2D2",
        "monthsTitle": "2+2 Months",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹516",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690475600877",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#C2D9F5",
        "monthsTitle": "100GB",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹780",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690475813488",
        "bgColor": "#074960",
        "monthsTitleBg": "#12333F",
        "monthsTitle": "2 Audiobooks",
        "monthsTitleColor": "#FFFFFF",
        "worthTitle": "Worth ₹396",
        "worthTitleColor": "#FFFFFF"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690476072222",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#FFD2D2",
        "monthsTitle": "12 Months",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹399",
        "worthTitleColor": "#343739"
    }
] */

const BrandImgsTwo = [
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009796",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#C2D9F5",
        "monthsTitle": "3 Months",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹249",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690476453220",
        "bgColor": "#E2481C",
        "monthsTitleBg": "#872205",
        "monthsTitle": "3 Months",
        "monthsTitleColor": "rgba(255, 255, 255, 0.70)",
        "worthTitle": "Worth ₹2190",
        "worthTitleColor": "#FFFFFF"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009673",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#C2D9F5",
        "monthsTitle": "6 Months",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹450",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202009854",
        "bgColor": "#1D3C90",
        "monthsTitleBg": "#22315B",
        "monthsTitle": "45 days",
        "monthsTitleColor": "#A6A7A8",
        "worthTitle": "Worth ₹675",
        "worthTitleColor": "#FFFFFF"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690204690066",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#F9D7CD",
        "monthsTitle": "Flat 18% off",
        "monthsTitleColor": "#343739",
        "worthTitle": "Savings upto ₹10,000",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202200646",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#CDCCF6",
        "monthsTitle": "1 Year Gold",
        "monthsTitleColor": "#343739",
        "worthTitle": "Worth ₹600",
        "worthTitleColor": "#343739"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2023/7/1690202200643",
        "bgColor": "#FFFFFF",
        "monthsTitleBg": "#EDEDED",
        "monthsTitle": "30% off",
        "monthsTitleColor": "#343739",
        "worthTitle": "Savings upto ₹6600",
        "worthTitleColor": "#343739"
    }
]

const BrandImgs = [
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828373",
        "bgColor": "linear-gradient(180deg, #0C623E 0%, #031D12 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC3960"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828275",
        "bgColor": "linear-gradient(180deg, #0A464D 0%, #021618 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC2989"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828337",
        "bgColor": "linear-gradient(180deg, #5A2F0B 0%, #1C0E03 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC3811"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828374",
        "bgColor": "linear-gradient(180deg, #590D2A 0%, #1F030E 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC8450"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301855094",
        "bgColor": "linear-gradient(180deg, #5F3F0B 0%, #171302 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC5518"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301977354",
        "bgColor": "linear-gradient(180deg, #4D0939 0%, #1B0A03 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC4041"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828336",
        "bgColor": "linear-gradient(180deg, #580C14 0%, #1E030B 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC1529"
    },
    {
        "imgUrl": "https://assets.gosats.io/notification/2024/2/1707301828335",
        "bgColor": "linear-gradient(180deg, #10316E 0%, #031521 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))",
        "faltReward": "Flat 10% Reward",
        "borderColor": "1px solid #FFFFFF33",
        "merchantId": "GSGC4949"
    }
]

const cardDetails = [

    {
        recommended: true,
        cardImg: "https://assets.gosats.io/notification/2024/4/1713333420769",
        cardTitle: "Elite Card",
        price: "₹499",
        oldPrice: "₹999",
        pricePer: "LIFETIME ACCESS",
        border: '1px solid #C9A67A',
        marginRight: '60px',
        bgColor: 'linear-gradient(180deg, #1A1917 0%, #2D2212 100%)',
        features: [
            {
                featureText: "Earn 1.5% flat rewards in Bitcoin or Gold"
            },
            {
                featureText: "2X rewards with voucher purchases"
            },
            {
                featureText: "2.5% increased rewards on Zomato vouchers"
            },
            {
                featureText: "6% increased rewards on Myntra vouchers"
            },
            {
                featureText: "10% increased rewards on PVR vouchers"
            },
            {
                featureText: "Physical card Delivery in 7 Days"
            }
        ]
    }
]

function Wazirx() {

    const targetDate = new Date('2024-07-01T23:59:59');
    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
    const [showTimer, setShowTimer] = useState(false);

    useEffect(() => {
        // Update remaining time every second
        const timer = setInterval(() => {
            const updatedRemainingTime = calculateRemainingTime();
            setRemainingTime(updatedRemainingTime);

            if (updatedRemainingTime.days <= 2 && !showTimer) {
                setShowTimer(true);
            }

        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(timer);
    }, []);

    function calculateRemainingTime() {
        const now = new Date();
        const difference = targetDate - now;

        // Check if the target time has already passed
        if (difference < 0) {
            return 0;
        }

        // Convert the difference to hours, minutes, and seconds
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds, days };
    }

    // const [monthlySpend, setMonthlySpend] = useState(20000); // Initialize the spending value
    const navigate = useNavigate();

    // const minMonthlySpend = 20000;
    // const maxMonthlySpend = 200000;
    // const step = 10000; // The step value for the range bar
    // const valueBack = 4.48;

    // useEffect(() => {
    //     document.getElementById("rangeInput").oninput = function () {
    //         var value = (this.value - this.min) / (this.max - this.min) * 100
    //         this.style.background = 'linear-gradient(to right, #6B39B2 0%, #6B39B2 ' + value + '%, #343739 ' + value + '%, #343739 100%)'
    //     };
    // }, []);

    // const calculateBenefits = (spend) => {
    //     const averageMonthlyBenefits = (spend * valueBack) / 100;
    //     const averageAnnualBenefits = averageMonthlyBenefits * 12;
    //     const food = (spend * 30) / 100;
    //     const travel = (spend * 10) / 100;
    //     const fashion = (spend * 10) / 100;
    //     const movie = (spend * 5) / 100;
    //     const other = (spend * 45) / 100;
    //     const totalSpend = food + travel + fashion + movie + other;
    //     const totalAnnualReward =
    //         10000 +
    //         food * 0.08 * 12 +
    //         travel * 0.04 * 12 +
    //         fashion * 0.06 * 12 +
    //         movie * 0.08 * 12 +
    //         other * 0.015 * 12;
    //     return {
    //         averageMonthlyBenefits,
    //         averageAnnualBenefits,
    //         food,
    //         travel,
    //         fashion,
    //         movie,
    //         other,
    //         totalSpend,
    //         totalAnnualReward,
    //     };
    // };

    // Event handler for range bar drag
    // const handleRangeChange = (e) => {
    //     const newSpend = parseInt(e.target.value);
    //     // Adjust monthlySpend to the nearest multiple of the step value
    //     setMonthlySpend(Math.round(newSpend / step) * step);
    // };

    // const {
    //     averageMonthlyBenefits,
    //     averageAnnualBenefits,
    //     food,
    //     travel,
    //     fashion,
    //     movie,
    //     other,
    //     totalSpend,
    //     totalAnnualReward,
    // } = calculateBenefits(monthlySpend);

    const copyToClipboard = () => {
        const textToCopy = "WAZIRX";
        const toastMessage = document.getElementById("toastMessage");
        navigator.clipboard.writeText(textToCopy);
        showToast(toastMessage);
    }

    function showToast(toastMessage) {
        toastMessage.classList.add("show");
        setTimeout(() => {
            toastMessage.classList.remove("show");
        }, 2000);
    }

    const navigateTop = () => {
        navigate('/partnership/WazirxTAndC')
    }

    return (
        <div className="landingScreenContainer">
            <div className="header">
                <div className='topNavBarContainer'>

                    <div className='wazirxHeadderTimer'>
                        <div className='rightLayout'>
                        </div>
                        <div className='leftLayout'>
                        </div>
                        <Marquee speed={30} gradientColor='none' direction='left'>
                            <div className='wazirxHeadderTimerTitle'>
                                Hurry! T20 World Cup Bonanza Ending Soon
                            </div>
                        </Marquee>
                        <div className='wtimerContainer' style={{ width: "90px" }}>
                            <img alt=' ' className='timerIcon' src={'https://assets.gosats.io/notification/2023/11/1700731674029'} />
                            <div className='timeCounter'>
                                {remainingTime === 0 ? (
                                    '00:00:00'
                                ) : (
                                    <div style={{ display: 'flex', gap: '2px', paddingRight: '15px', marginBottom: '2px' }}>
                                        {`${String(remainingTime.days * 24 + remainingTime.hours).padStart(2, '0')}:${String(remainingTime.minutes).padStart(2, '0')}:${String(remainingTime.seconds).padStart(2, '0')}`}
                                    </div>
                                    /* <div style={{ display: 'flex', gap: '2px', paddingRight: '0px'}}>
                                        {remainingTime.days} Days left
                                    </div> */
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='headderDivider'></div>
                    <div className='topNavBar'>
                        <img src="https://assets.gosats.io/notification/2023/7/1689325079704" alt="logo" className="logo" />
                        <a href="https://gosats.onelink.me/O1LB/6zj6lybc" target="_blank" rel="noopener noreferrer" style={{ zIndex: 9999, textDecoration: 'none' }}>
                            <div className='downloadCTA'>Download the App</div>
                        </a>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='wazirxheaderTitle col-12'>
                        {/* <div><img alt=' ' className='blackFridayImg' src={"https://assets.gosats.io/notification/2024/3/1711110132683"} /></div> */}
                        <div className='wazirxCodeTitle'>T20 World Cup Bonanza</div>
                        <div className='wazirxCodeSubTitle'>Free Virtual VISA card & Upgrade to the Elite card at <span style={{ color: "rgba(255, 255, 255, 0.50)" }}><s style={{ color: "rgba(255, 255, 255, 0.50)" }}>₹999</s></span> <span className='wazirxheaderTitleMidOne'> ₹499 </span></div>
                        {/* <div className='wazirxCodeTitle'>
                        Get <span className='wazirxCodeTitleColor'> 2% cashback </span> on <span className='wazirxCodeTitleColor'> Gold Rewards </span>
                    </div> */}
                    </div>
                </div>
                {/* <div className='headerTAndCTitle'>T&C apply</div> */}
                <div className='headerImageContainer'>
                    <img src='https://assets.gosats.io/notification/2024/6/1718801857286' alt='image' className='headerImage' />
                    <div className='bodyCtaContainer'>
                        <button className='bodyCta'
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "https://gosats.onelink.me/O1LB/6zj6lybc";
                            }}
                        >Get The GoSats Card</button>
                    </div>
                </div>
            </div>
            <div className='wazirxCardContainer'>
                <div>
                    <img src='https://assets.gosats.io/notification/2023/11/1700807757610' className='cardImage' alt='' />
                </div>
                <div className='cardTitle col-6'>
                    Card powered by Visa Platinum. Earn Rewards in <span className='cardRewardTitleColor'>Gold or Bitcoin </span>on every spend
                </div>
            </div>
            <div className='bodyContainer'>
                <div className='bodyCardOne'>
                    <div className='wazirxCodeSubContainer'>
                        <img src='https://assets.gosats.io/notification/2023/7/1690194510080' alt='image' className='bodyCardOneImg' />
                        <div className='bodyExclusive'>
                            Exclusive!
                        </div>
                    </div>
                    <div className='wazirxSubCodeTitle'>
                        {/* Use Coupon code and receive an <span className='wazirxCodeTitleColor'> exclusive discount </span> during checkout */}
                        Use the Coupon/Referral code to get the Elite card for ₹499
                    </div>
                    <div className='referralCodeContainer'>
                        <div className='referralTitle'>
                            COUPON CODE:
                        </div>
                        <div className='referralCode'>
                            <span>WAZIRX</span> <span><button className='copyIconButton' onClick={() => copyToClipboard('WAZIRX')}><img src='https://assets.gosats.io/notification/2023/7/1690355380389' className='copyIcon' /></button></span>
                        </div>
                    </div>
                </div>
                {/* <div className='cardRewardContainer'>
                    <div className='cardReward'>
                        <div className='cardRewardTitle'>
                        1.5% <span className='cardRewardTitleColor'> Bitcoin </span> Back on Everything!
                        </div>
                        <div className='cardRewardTitle'>
                            <span className='cardRewardTitleColor'>1.5% Bitcoin or Gold </span> back every time you shop
                            Earn <span className='cardRewardTitleColor'> 1.5% Bitcoin </span> or <span className='cardRewardTitleColor'>2% Gold </span> back every time you shop*
                        </div>

                        <div>
                            <img src='https://assets.gosats.io/notification/2023/7/1690362865210' className='cardRewardImg' alt='' />
                        </div>
                    </div>
                </div> */}
                <div style={{ marginTop: '50px', padding: '0px 20px', backgroundColor: '#0f0f0f' }}>
                    {/* <div style={{ textAlign: 'center' }}>
                        <img alt=' ' className='blackFridayImgTwo' src={"https://assets.gosats.io/notification/2024/3/1711110132683"} />
                    </div> */}
                    <div className='wazirxCodeTitle' style={{ textAlign: 'center', fontSize: '18px' }}>T20 World Cup Bonanza</div>
                    <div className='cardSectionSubTitleContainer'>
                        <div className='cardSectionSubTitle'>Offer Valid for 72 Hours!</div>
                    </div>
                    <div className="cardDetailsContainer ">
                        <div className="chooseCardOneContainer">
                            {cardDetails.map((data, index) => (
                                <div className="chooseCardOne" key={index}>
                                    <div>
                                        {/* {data.recommended ? <div className="recommendedCard">Recommended</div> : ""} */}
                                    </div>
                                    <img className="cardImg" src={data.cardImg} alt="card" />
                                    <div className="chooseCardTitle">{data.cardTitle}</div>
                                    <div className="cardPriceContainer">
                                        <div className="cardPrice">{data.price}<span className='oldPrice'><s>{data.oldPrice}</s></span></div>
                                        <div className="perTitle">{data.pricePer}</div>
                                    </div>
                                    <div className="cardDivider"></div>
                                    {data.features.map((feature, index) => (
                                        <div className="checkPoints" key={index}>
                                            <div className="checkTitle col-12" style={{ color: data.textColor }}>{feature.featureText}</div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className="topBrandsContainer" style={{ marginTop: '50px', marginBottom: '0px' }}>
                        <div className="valentineTopBrandsTitle" style={{ margin: "0px 20px", marginBottom: "30px" }}>Flat 10% rewards on your favourite brand vouchers with the Elite card</div>
                        <div className="valentineTopBrandsIconContainerOne">
                            {
                                BrandImgs.map((data, index) => (
                                    <div key={index} className="valentineTopBrandsIconContainer" style={{ background: data.bgColor, border: data.borderColor }}>
                                        <div>
                                            <img className="valentineTopBrandsIcon" alt=" " src={data.imgUrl} />
                                        </div>
                                        <div className="valentineTopBrandsIconTitleContainer">
                                            <div className="valentineTopBrandsIconTitle">
                                                {data.faltReward}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='wazirxFooterContainer'>
                <div className='wazirxFooterContainerTitle'>
                    Get India’s Most Rewarding Card Instrument
                </div>
                <div className='bodyCtaContainer'>
                    <button className='bodyCta'
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = "https://gosats.onelink.me/O1LB/6zj6lybc";
                        }}
                    >Get the GoSats Card</button>
                </div>
                <div className='wazirxFooterSubtitle'>
                    Don’t Miss Out on the Limited Time Offer
                </div>
                <div class="toast" id="toastMessage">Code has been copied!</div>
                <button onClick={() => { navigateTop() }} className='tandc'><u style={{ opacity: 1 }}><span style={{ opacity: 0.5 }}>View our Terms and Conditons </span></u></button>
            </div>
            {/* <div className='favBrandsListContainer'>
                <div className='favBrandsListTitle'>
                    ₹10,000 <span style={{ fontSize: '26px' }}> Worth of Perks and Delights</span>
                </div>
                <div className='favBrandsListSubTitle'>
                    Experience the GoSats advantage with exclusive access to more than 20 beloved app subscriptions, unbeatable deals and benefits worth <span style={{ color: '#FFE099' }}> ₹10,000 </span> with complimentary Times Prime with the GoSats Elite card.
                </div>
                <div>
                    <div className='rightLayout'>
                    </div>
                    <div className='leftLayout'>
                    </div>
                    <div className='brandContainer'>
                        <Marquee speed={50} gradientColor='none' direction='rtl'>
                            <div className='brandSubContainerOne'>
                                {
                                    BrandImgs.map((data, index) => (
                                        <div className='brandImageContainerRowOne' style={{ background: data.bgColor }} key={index}>
                                            <img src={data.imgUrl} alt="images" className='brandImages' />
                                            <div className='monthContainer'>
                                                <div className='months' style={{ background: data.monthsTitleBg, color: data.monthsTitleColor }}>{data.monthsTitle}</div>
                                            </div>
                                            <div style={{ color: data.worthTitleColor }} className='worthPrice'>{data.worthTitle}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Marquee>
                        <Marquee speed={50} gradientColor='none'>
                            <div className='brandSubContainerOne'>
                                {
                                    BrandImgsTwo.map((data, index) => (
                                        <div className='brandImageContainerRowTwo' style={{ background: data.bgColor }} key={index}>
                                            <img src={data.imgUrl} alt="images" className='brandImages' />
                                            <div className='monthContainer'>
                                                <div className='months' style={{ background: data.monthsTitleBg, color: data.monthsTitleColor }}>{data.monthsTitle}</div>
                                            </div>
                                            <div style={{ color: data.worthTitleColor }} className='worthPrice'>{data.worthTitle}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Marquee>
                    </div>
                </div>
            </div> */}
            {/* <div className='rangeBarContainer'>
                <div className='rangeBarHeader'>
                    Find out how much you can earn
                </div>
                <div className='rangeBarSubContainer'>
                    <div className='rangeBarTitle'>Average Monthly Spends</div>
                    <div className='value'>₹{monthlySpend}</div>
                    <div className='barContainer'>
                        <div>
                            <input type='range' id="rangeInput" value={monthlySpend} min={minMonthlySpend} max={maxMonthlySpend} onChange={handleRangeChange} className='rangeBar' />
                            <div className='rangeBarAmount'>
                                <div>{minMonthlySpend}</div>
                                <div>{maxMonthlySpend}</div>
                            </div>
                        </div>
                    </div>
                    <div className='monthlyAnnualContainer'>
                        <div className='valueBack'>VALUEBACK</div>
                        <div className='valuePer'><img src='https://assets.gosats.io/notification/2023/7/1690219012910' alt='icon' className='valueIcon' />4.48%</div>
                        <div className='mnthAndYear'>
                            <div>
                                <div className='mnthlyTitle'>Average monthly benefits</div>
                                <div className='mnthlyAmnt'>₹{averageMonthlyBenefits.toFixed(0)}</div>
                            </div>
                            <div className='divider'></div>
                            <div>
                                <div className='mnthlyTitle'>Average annual benefits</div>
                                <div className='mnthlyAmnt'>₹{averageAnnualBenefits.toFixed(0)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='monthlyAnnualContainer' style={{ padding: '0px' }}>
                        <table style={{ width: '100%', borderRadius: '10px 10px 0px 0px', overflow: 'hidden' }}>
                            <thead style={{ width: '100%' }}>
                                <tr className='benefitsHeader'>
                                    <th className='tableHead'>BENEFITS</th>
                                    <th className='tableHead'>SPENDS PER MONTH</th>
                                    <th className='tableHead' style={{ padding: '8px 10px 8px 10px' }}>ANNUAL REWARD VALUE</th>
                                </tr>
                            </thead>
                            <tbody style={{ width: '100%', textAlign: 'left' }} className='tableBodyData'>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Times Prime</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}></td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹10000</td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Food & Groceries</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}>₹{food.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹{(food * 0.08 * 12).toFixed(0)}</td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Travel</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}>₹{travel.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹{(travel * 0.04 * 12).toFixed(0)}</td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Fashion</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}>₹{fashion.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹{(fashion * 0.06 * 12).toFixed(0)}</td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Movies</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}>₹{movie.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹{(movie * 0.08 * 12).toFixed(0)}</td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '20%', padding: '10px' }}>Other</td>
                                    <td className='tableBodyValue' style={{ width: '25%', padding: '10px' }}>₹{other.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '55%', padding: '10px' }}>₹{(other * 0.015 * 12).toFixed(0)}</td>
                                </tr>
                                <tr style={{ width: '50% !important' }}>
                                    <td colSpan="3" >
                                        <div className="divider-row"></div>
                                    </td>
                                </tr>
                                <tr style={{ width: '100%' }}>
                                    <td className='tableBodyTitle' style={{ width: '45%', padding: '10px' }}>Total</td>
                                    <td className='tableBodyValue' style={{ width: '20%', padding: '10px' }}>₹{totalSpend.toFixed(0)}</td>
                                    <td className='tableBodyValue' style={{ width: '60%', padding: '10px' }}>₹{totalAnnualReward.toFixed(0)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='bottomDownloadButtonContainer'>
                    <a href="https://gosats.page.link/m8uv" target="_blank" rel="noopener noreferrer" style={{ zIndex: 9999, textDecoration: 'none' }}>
                        <div className='bottomDownloadButton'>
                            Download the App
                        </div>
                    </a>
                </div>
                <div class="toast" id="toastMessage">Code has been copied!</div>
                <button onClick={() => { navigateTop() }} className='tandc'><span style={{ opacity: 0.5 }}>*</span><u style={{ opacity: 1 }}><span style={{ opacity: 0.5 }}>Terms and Conditions can be found </span> <span>here</span></u></button>
            </div> */}
        </div>
    )
}

export default Wazirx;