import React, { useEffect } from "react";

function WazirxTAndC() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page after navigation
    }, []);

    return (
        <div className="WazirxTAndCContainer" style={{ padding: '20px', background: '#0F0F0F', fontSize: '16px' }}>
            <h2>Terms and Conditions for GoSats Bitcoin Bonanza Offer for WazirX Users</h2>
            <ol style={{ marginTop: '20px' }}>
                <li>The GoSats T20 World Cup Bonanza Offer for WazirX Users offer (“the offer”) is applicable only to users who sign up on GoSats using the referral code “WAZIRX”, or use the discount code “WAZIRX”</li>
                <li>The user will need to sign up and complete the Min KYC to avail the virtual card.</li>
                <li>To be eligible for the offer, users must purchase the GoSats Elite Card during the offer period.</li>
                <li>The GoSats Elite Card will be available at Rs499 during the offer period and will have life time validity.</li>
                <li>The discount offer is applicable only from 28th June 2024 and 1st July 2024 till 12PM. The discount offer can be availed only once per user.</li>
                <li>The offer cannot be combined or clubbed with any other discount codes or promotions.</li>
                <li>Disputes arising in connection with the offer shall be subject to the jurisdiction of the courts in Bangalore.</li>
                <li>GoSats reserves the right to modify or cancel the offer at any time without prior notice to users.</li>
                <li>GoSats reserves the right to disqualify any user from availing the offer if there is any suspicion of fraudulent activities or violation of the terms and conditions.</li>
                <li>The offer is subject to the applicable laws.</li>
                <li>Users are required to comply with all KYC and verification requirements as per GoSats’s policies and Indian regulations.</li>
                <li>By participating in the offer, users agree to be bound by these terms and conditions and any decision made by GoSats regarding the offer.</li>
                <li>Please note that these terms and conditions are subject to change, and it is recommended to refer to the official GoSats website or communication channels for the most up-to-date information on the offer.</li>
            </ol>
            {/*  
            <h2>Addemdum for GoSats Rakshabandhan campaign offer for WazirX users:</h2>
            <ol>
                <li>The GoSats Rakshabandhan campaign offer is applicable only to users who signup on GoSats using referral code "WAZIRX" in the duration of 29th August 2023 to 5th September 2023.</li>
                <li>Upon a user on completing signup in the offer period will receive Bitcoin worth Rs. 100 in their GoSats account.</li>
                <li>This signup offer reward can be redeemed against purchases in the GoSats platform or be withdrawal as per the T&C of GoSats platform.</li>
                <li>By participating in the offer, the user agrees to be bound by these terms and conditions of the GoSats Rakshabandhan campaign as well as GoSats Elite Card for Wazirx Users and GoSats platform T&C.</li>
            </ol> 
        */}
        </div>
    )
}

export default WazirxTAndC;