import React, { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { FaChevronLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { ShimmerButton } from "react-shimmer-effects";
import timer from '../images/timer.png';
import crctAns from '../images/crctAns.png';
import wrngAns from '../images/wrngAns.png';
import curntQues from '../images/curntQues.png';
import imgOption from '../images/option.png';
import selectOption from '../images/selectOption.png';
import { Link, useNavigate } from 'react-router-dom';
import Congratulation from './congratulation';
// import * as APIHelper from '../Helper/mockAPIFetch';
import QuizBackPopup from './QuizBackPopup';
import * as APIHelper from '../Helper/apiFetch';

const shimmer1 = [

    {
        rows: 1,
        width: "100%",
        height: 55
    }
]

const shimmer2 = [
    {
        rows: 1,
        width: "100%",
        height: 40
    },
    {
        rows: 1,
        width: "100%",
        height: 40
    },
    {
        rows: 1,
        width: "100%",
        height: 40
    },
    {
        rows: 1,
        width: "100%",
        height: 40
    }
]

const MILESTONE_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    CORRECT: 2,
    WRONG: 3
}

const getMilestoneImg = (index) => {
    if (index === MILESTONE_STATUS.INACTIVE)
        return;
    if (index === MILESTONE_STATUS.ACTIVE)
        return <img className='range-correct-Ans' src={curntQues} alt="current answer" />;
    if (index === MILESTONE_STATUS.CORRECT)
        return <img className='range-correct-Ans' src={crctAns} alt="correct answer" />;
    if (index === MILESTONE_STATUS.WRONG)
        return <img className='range-correct-Ans' src={wrngAns} alt="wrong answer" />;
}

const initiaData = require('./urlEncodeTest.json')

const Quiz = () => {

    const [quizData, setQuizData] = useState([]);
    const [responses, setResponses] = useState({});
    const [counter, setCounter] = useState(20);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentIndexSelected, setCurrentIndexSelected] = useState(null);
    const [correctOption, setcorrectOption] = useState(null);
    const [score, setScore] = useState(0);
    const [enableNextButton, setEnableNextButton] = useState(false);
    const [loadingNextQuestion, setLoadingNextQuestion] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [claimingReward, setClaimingReward] = useState(false);
    const [milestoneImg, setMilestoneImg] = useState([1, 0, 0, 0, 0]);
    const [congratsTitle, setCongratsTitle] = useState(initiaData.streak.CongratulationsPopup);
    const [showCongrats, setShowCongrats] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [quizQuitPopup, setQuizQuitPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const loadingNextQuestionRef = useRef(loadingNextQuestion);
    const currentQuestionIndexRef = useRef(currentQuestionIndex);
    const currentIndexSelectedRef = useRef(currentIndexSelected);
    const quizDataRef = useRef(quizData);
    const timerRef = useRef();


    const didNotAnswerTitle = initiaData.streak.quizWrongAnsweredPopup;

    const navigate = useNavigate();

    useEffect(() => {
        window.onpopstate = e => {
            console.log(e);
            navigate('/')
        }
    })

    useEffect(() => {
        loadingNextQuestionRef.current = loadingNextQuestion;
        setTimeout(() => {
            if (loadingNextQuestion) {
                setTimeout(() => {
                    handelNextQuestion();
                }, 2000);
            }
        })
    }, [loadingNextQuestion]);

    useEffect(() => {
        currentQuestionIndexRef.current = currentQuestionIndex;
    }, [currentQuestionIndex]);
    useEffect(() => {
        currentIndexSelectedRef.current = currentIndexSelected;
    }, [currentIndexSelected]);

    useEffect(() => {
        quizDataRef.current = quizData;
    }, [quizData]);

    const intervalFunc = () => {
        setCounter(c => {
            if (loadingNextQuestionRef.current) {
                setButtonDisable(true);
                clearInterval(timer)
            } else if (c === 0) {
                handelNext();
                setButtonDisable(true);
                clearInterval(timer);
            } else {
                c = c - 1;
                setButtonDisable(false);
            }
            return c;
        });
    }

    useEffect(() => {
        setLoader(true);
        APIHelper.startQuiz().then(resp => {
            console.log(resp)
            setLoader(false);
            if (resp.error) {
                navigate('/error');
                return;
            }
            console.log(resp, "test")
            if (resp?.data?.questions?.length > 0) {
                setQuizData(resp.data.questions);
                const defaultResponses = {};
                resp.data.questions.forEach(q => {
                    defaultResponses[q.id] = -2;
                })
                setResponses(defaultResponses)
                if (!timerRef.current)
                    timerRef.current = setInterval(intervalFunc, 1000);
            }
        })
        return () => clearInterval(timerRef);
    }, [])


    const validateAnswer = (selectedIndex) => {
        const correct_Option = quizDataRef.current[currentQuestionIndex].correctOption;
        setResponses(r => {
            r = {
                ...r,
                [quizDataRef.current[currentQuestionIndex]['id']]: selectedIndex
            }
            return r;
        })
        setCurrentIndexSelected(selectedIndex);
        setcorrectOption(correct_Option);
        if (selectedIndex === correct_Option) {
            setScore(score + 1);
        }

        // Show next button
        setEnableNextButton(true);
    }

    const renderQuestion = () => {
        return (
            <div>
                {
                    quizData[currentQuestionIndex]?.imageURL ?
                        <div className='quiz-questions-img'>
                            <div className='quiz-questions'>{quizData[currentQuestionIndex]?.question}</div>
                            <img src={quizData[currentQuestionIndex]?.imageURL} alt=' ' style={{ borderRadius: '10px' }} />
                        </div>
                        :
                        <div className='quiz-questions'>{quizData[currentQuestionIndex]?.question}</div>
                }
            </div>
        )
    }

    const submitResponses = () => {
        console.log(responses);
        if (claimingReward) return;
        setClaimingReward(true);
        APIHelper.submitQuiz(responses).then((resp) => {
            console.log(resp);
            if (resp.error) {
                navigate('/error');
                return;
            } else if (resp.rewards.value === 0) {
                //show error popup
                setShowErrorPopup(true);
            } else {
                setDiscountCode(resp?.rewards?.code);
                setCongratsTitle(title => {
                    title.reward = resp?.rewards?.value;
                    title.code = resp?.rewards?.code;
                    if (resp.rewards.type === "sats") {
                        title.msg1 = title.msg1?.replace("$", title.reward)
                    } else if (resp.rewards.type === "card-percentage") {
                        title.msg1 = title.percentageTitle?.replace("$", title.reward)
                    } else {
                        title.msg1 = title.cardDiscount?.replace("$", title.code)
                    }
                    return title;
                })
                // show congratulation you've won popup
                setShowCongrats(true);
            }
        })
    }

    const renderNextButton = () => {
        if (loadingNextQuestion) {
            if (currentQuestionIndex === quizData.length - 1) {
                return (
                    <div className='quiz-next-button-container'>
                        <button className='quiz-next-button'  style={(enableNextButton) ? { background: '#FF00A5', color: '#FFFFFF', boxShadow: "5px 9px #D1068A" } : null}><div style={{ marginRight: '10px', marginTop: '3px' }}><ClipLoader color={'#FFFFFF'} size={17} /></div>PLEASE WAIT</button>
                    </div>
                )
            }
            return (
                <div className='quiz-next-button-container'>
                    <button className='quiz-next-button-loaging' onClick={handelNextQuestion}><div style={{ marginRight: '10px', marginTop: '3px' }}><ClipLoader color={'#FFFFFF'} size={17} /></div>LOADING NEXT QUESTION</button>
                </div>
            )
        } else if (currentQuestionIndex === quizData.length - 1) {
            return (
                <div className='quiz-next-button-container'>
                    <button className='quiz-next-button' disabled={!enableNextButton} onClick={handelNext} style={(enableNextButton) ? { background: '#FF00A5', color: '#FFFFFF', boxShadow: "5px 9px #D1068A" } : null}>NEXT <FaArrowRight style={{ marginLeft: '10px' }} /></button>
                </div>
            )
        } else {
            return (
                <div className='quiz-next-button-container'>
                    <button disabled={!enableNextButton} className='quiz-next-button' style={(enableNextButton) ? { background: '#FF00A5', color: '#FFFFFF', boxShadow: "5px 9px #D1068A" } : null} onClick={handelNext}>NEXT <FaArrowRight style={{ marginLeft: '10px' }} /></button>
                </div>
            )
        }
    }

    const handelNext = () => {
        const correct_Option = quizDataRef.current[currentQuestionIndexRef.current]?.correctOption;
        if (!enableNextButton) setEnableNextButton(true);
        setShowAnswer(true);
        setcorrectOption(correct_Option);
        setLoadingNextQuestion(true);
        const rightOrWrong = quizDataRef.current[currentQuestionIndexRef.current]?.correctOption === currentIndexSelectedRef.current;
        setMilestoneImg(s => {
            s[currentQuestionIndexRef.current] = rightOrWrong ? MILESTONE_STATUS.CORRECT : MILESTONE_STATUS.WRONG;
            return s;
        })
    }


    const renderOption = () => {
        const getOptionStyle = (i) => {
            if (i === currentIndexSelected) {
                if (showAnswer && i === correctOption) return { border: '1px solid #18D887', backgroundColor: '#112920' }
                if (showAnswer && i !== correctOption) return { border: '1px solid #FB604A', backgroundColor: '#311D1B' }
                return { border: '1px solid #D1964A' };
            } else if (i === correctOption && showAnswer) {
                return { border: '1px solid #18D887', backgroundColor: '#112920' }
            }
        }
        return (
            <div>
                {
                    quizData.length > 0 && quizData[currentQuestionIndex].options.map((option, index) => {
                        return (
                            <div key={index} style={{ overflowY: 'scroll' }}>
                                <button disabled={buttonDisable} className='quiz-options-container' onClick={() => validateAnswer(index)} style={getOptionStyle(index)}>
                                    <img style={{ width: '16px', marginRight: '10px' }} src={index === currentIndexSelected ? selectOption : showAnswer ? (index === correctOption ? selectOption : imgOption) : imgOption} alt=' ' />
                                    {option}
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const handelNextQuestion = () => {
        if (currentQuestionIndex === quizData.length - 1) {
            submitResponses();
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            console.log("test")
            setMilestoneImg(s => {
                s[currentQuestionIndex + 1] = MILESTONE_STATUS.ACTIVE;
                return s;
            })
            setcorrectOption(null);
            setEnableNextButton(false);
            setCurrentIndexSelected(null);
            setShowAnswer(false);
            setLoadingNextQuestion(false)
            setCounter(20);
        }
    }

    const shimerLoader1 = () => {
        return (shimmer1?.map((data, index) => {
            return (
                <div key={index}>
                    <ShimmerButton size={{ height: data?.height, width: data?.width }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows} foreground={'#191B30'} background={'#292B47'} />
                </div>
            )
        }
        ))
    }

    const shimerLoader2 = () => {
        return (shimmer2?.map((data, index) => {
            return (
                <div key={index}>
                    <ShimmerButton size={{ height: data?.height, width: data?.width }} style={{ marginBottom: '15px' }} elevation={0.1} rows={data?.rows} foreground={'#191B30'} background={'#292B47'} />
                </div>
            )
        }
        ))
    }

    const renderProgressBar = () => {
        return (
            <div className='quiz-rangebar-container'>
                <div className='rangecheckbg'>{getMilestoneImg(milestoneImg[0])}</div>
                <div className='rangecheckbar'>{currentQuestionIndex + 1 > 1 ? <div className='range-complt-qust'></div> : null}</div>
                <div className='rangecheckbg'>{getMilestoneImg(milestoneImg[1])}</div>
                <div className='rangecheckbar'>{currentQuestionIndex + 1 > 2 ? <div className='range-complt-qust'></div> : null}</div>
                <div className='rangecheckbg'>{getMilestoneImg(milestoneImg[2])}</div>
            </div>
        )
    }




    return (
        <div className='container bgImage'>
            <div className='quizNavbar pt-4'>
                <div onClick={() => { setQuizQuitPopup(true) }}>
                    <FaChevronLeft className='quizBack-button-icon' />
                </div>
                <div className='quizNavbar-title'>Cricket Trivia</div>
            </div>
            <div>
                {
                    renderProgressBar()
                }
            </div>
            <div className='quiz-question-section'>
                <div className='quizTimerContainer'>
                    <div className='quizTimer'>
                        Timer
                        <img src={timer} className='timer-Icon' alt=' ' />
                        :
                        <div className='quizTimerBg' style={{ background: counter <= 5 ? '#FB604A' : '#FF00A5', color: counter <= 5 ? '#4D302D' : '#FFFFFF' }}>{counter}</div>
                    </div>
                </div>
                <div>
                    <div>
                        {loader ? shimerLoader1() : renderQuestion()}
                    </div>

                    <div>
                        {loader ? shimerLoader2() : renderOption()}
                    </div>
                </div>
            </div>
            <div className='nextButtonContainer'>
                {renderNextButton()}
            </div>
            <div>
                {showCongrats && <Congratulation title={congratsTitle} discountCode={discountCode} />}
                {showErrorPopup && <Congratulation title={didNotAnswerTitle} quiz={false} />}
                {quizQuitPopup && <QuizBackPopup />}
            </div>
        </div>
    )
}

export default Quiz;