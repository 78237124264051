import logo from '../images/logo.png';
import headerImage from '../images/headerImage.png'
import * as nativeEvents from '../Helper/native';
import { FiChevronLeft } from 'react-icons/fi';

const Header = () => {

    return (
        <div className='headder'>
            <div className='pt-5'>
                <div style={{marginLeft: '20px'}}>
                    <button className='back-button' onClick={nativeEvents.goBack}>
                        <FiChevronLeft className='back-button-icon' />
                    </button>
                </div>
                <div className='log-container'>
                    {/* <img className='logo' src={logo} alt=" " /> */}
                    <div className='head-title'>IPL 2024</div>
                    <div className='head-subtitle'>Predict & Win</div>
                    <div className='head-subtitleOne'>
                        Predict the winner of IPL matches and stand a chance to win <span className='head-subtitleOne-linear'> 10,000 Sats</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header