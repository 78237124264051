

import { useRef, useState, useEffect } from "react";
// import Confetti from 'react-confetti';
import Confetti from "react-confetti";


export default function ConfettiComp(){
    const [timer, setTimer] = useState(false);
    const [stopConfetti, SetstopConfetti] = useState(true);
    const timerRef = useRef();

    useEffect(() => {
        if (timer) return
        timerRef.current = setInterval(() => {
            SetstopConfetti(false)
        }, 4 * 1000);
        setTimer(false);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [])

    return(
        <div style={{position:'absolute'}}>
        {
            stopConfetti ?
                <Confetti
                    width="500"
                    height="800"
                    run={true}
                    numberOfPieces={150}
                />
                :
                null
        }
        </div>
    )
}