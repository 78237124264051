import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { ClipLoader } from 'react-spinners';
import * as APIHelper from '../Helper/apiFetch';
// import * as APIHelper from '../Helper/mockAPIFetch';
import ScratchCardLoader from "../components/UI/ScratchCardLoader";
import CardCashback from "../components/GiftType/CardCashback";
import giftType from "../components/GiftType";

export default function Rewards() {
    const [loader, setLoader] = useState(true);
    const [climeRewardLoader, setClimeRewardLoader] = useState(false);
    const [congPopup, setCongPopup] = useState(false)
    const [data, setData] = useState([]);
    const [reward, setReward] = useState();
    const [rewardDetails, setRewardDetails] = useState();
    const [rewardSubType, serRewardSubType] = useState();
    const [id, setId] = useState(null);
    const [referralCode, setReferralCode] = useState("");
    const [link, setReferralLink] = useState('');
    const [merchantID, setMerchantID] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        APIHelper.getGiftCards().then(resp => {
            if (resp.error) {
                navigate('/error');
            }
            console.log(resp)
            setLoader(false);
            setData(resp.gifts);
        })
    }, [])

    useEffect(() => {
        APIHelper.getGiftCards().then(resp => {
            if (resp.error) {
                navigate('/error');
            }
            console.log(resp)
            setData(resp.gifts);
        })
    }, [climeRewardLoader])

    const handelReward = async () => {
        setClimeRewardLoader(true);
        try {
            await APIHelper.claimGiftCard().then(resp => {
                if (resp.error) {
                    navigate('/error');
                }
                setClimeRewardLoader(false)
                setCongPopup(true);
                setReward(resp);
                serRewardSubType(resp?.subtype);
                setRewardDetails(resp?.details);
                setId(resp?.id);
                setMerchantID(resp?.info?.merchantId);
                setReferralCode(resp?.info?.code);
                setReferralLink(resp?.info?.link);
                APIHelper.postAnalytics("popup_click", resp.type);
                console.log(resp?.details);
                console.log(resp);
            })
        } catch (e) {
            console.log("Error", e);
        }
    }

    const Loader = () => {
        return (
            <div className="rewardLoader">
                <div style={{ color: '#FFFFFF' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <ClipLoader size={24} color={'#D1964A'} />
                    </div>
                </div>
            </div>
        )
    }


    const checkReward = () => {

        if (reward?.type === 'cardcashback') {
            if (rewardSubType === 'nocard') {
                return (
                    <div>
                        {congPopup && <giftType.NoCard reward={rewardDetails} />}
                    </div>
                )
            } else if (rewardSubType === 'intro') {
                return (
                    <div>
                        {congPopup && <giftType.CardCashback reward={rewardDetails} />}
                    </div>
                )
            } else if (rewardSubType === "elite") {
                return (
                    <div>
                        {congPopup && <giftType.CardCashback reward={rewardDetails} />}
                    </div>
                )
            }
        } else if (reward?.type === 'sendGift') {
            return (
                <div>
                    {congPopup && <giftType.SendGift reward={rewardDetails} />}
                </div>
            )
        } else if (reward?.type === 'flatSats') {
            return (
                <div>
                    {congPopup && <giftType.FlatSats reward={rewardDetails} />}
                </div>
            )
        } else if (reward?.type === 'merchantDiscount') {
            return (
                <div>
                    {congPopup && <giftType.MerchantDiscount reward={rewardDetails} merchantID={merchantID} />}
                </div>
            )
        } else if (reward?.type === 'referalBonus') {
            console.log("Reffer")
            return (
                <div>
                    {congPopup && <giftType.ReferralBonus reward={rewardDetails} code={referralCode} link={link} />}
                </div>
            )
        } else if (reward?.type === 'twoxReward') {
            return (
                <div>
                    {congPopup && <giftType.TwoxRewards reward={rewardDetails} />}
                </div>
            )
        }
    }

    return (
        <div>
            {
                loader ?
                    <div>
                        <Loader />
                    </div>
                    :
                    <div>
                        {
                            climeRewardLoader ?
                                <ScratchCardLoader />
                                :
                                <div style={{ padding: '20px', color: '#FFFFFF' }}>
                                    <div className="rewardsCardHeader">
                                        <button className="rewardsCard-back-button-icon" onClick={() => navigate('/home')}><FiChevronLeft className='back-button-icon' /></button>

                                        <div className="topStackers-title">Christmas Mystery Rewards</div>
                                    </div>
                                    <div style={{ marginTop: '20px' }}>
                                        <div className="topStackers-title">
                                            Upcoming gifts
                                        </div>
                                        <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                            {
                                                data?.map((data, index) => {
                                                    return (
                                                        <div className="giftCardsSection" key={index}>
                                                            <button className="giftRewardCardButton" onClick={handelReward} disabled={data.disabled}>
                                                                <img className="giftCardsSectionBgImg" src={data.image} alt="" />
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {checkReward()}
                                </div>
                        }
                    </div>
            }
        </div>
    )
}


